import React, { useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT;

function ResponsiveDataTable({ handleChange }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const machineData = JSON.parse(localStorage.getItem('datas_machines')) || [];
    setCategories(machineData);
  }, []);

  return (
    <>
    <style>
        {`
          .hoverable-td {
            max-width: 130px;
            white-space: pre-wrap;
            cursor: pointer;
          }

          .hoverable-td:hover {
            color: #0d6efd !important ; /* Change color on hover */
          }
        `}
      </style>
      {categories.map((category) => (
        <CategoryTable
          key={category.categorieId}
          category={category}
          handleChange={handleChange}
        />
      ))}
    </>
  );
}

function CategoryTable({ category, handleChange }) {
  const [machinesData, setMachinesData] = useState([]);
  const machinesDataRef = useRef(machinesData);

  useEffect(() => {
    const initializeMachineData = () => {
      const initialData = category.machines.map(machine => ({
        machine_id: machine.machine_id,
        machine_name: machine.machine_name,
        etat: -1,
        VL1: 0,
        VL2: 0,
        VL3: 0,
        U12: 0,
        U23: 0,
        U31: 0,
        AL1: 0,
        AL2: 0,
        AL3: 0,
        PFL1: 0,
        PFL2: 0,
        PFL3: 0,
        PL1: 0,
        PL2: 0,
        PL3: 0,
        PT: 0,
        PFM: 0,
        VLM: 0,
        UM: 0,
      }));

      return initialData;
    };

    const initialData = initializeMachineData();
    localStorage.setItem('datas_machines_real_time', JSON.stringify(initialData));
    setMachinesData(initialData);
    machinesDataRef.current = initialData;
  }, [category]);

  useEffect(() => {
    const socket = io(ENDPOINT);

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket.on("data", (data) => {
      if (data.cosphi && data.cosphi.cosphi1 && data.cosphi.cosphi2 && data.cosphi.cosphi3) {
        const updatedData = machinesDataRef.current.map(machine => {
          if (machine.machine_id === data.machine.id_machine) {
            if(data.machine.id_machine === 'tgbt' ){
              const ondMTData = machinesDataRef.current.find(machine => machine.machine_id === process.env.REACT_APP_DEFAULT_OND_MT);
              const departData = machinesDataRef.current.find(machine => machine.machine_id === process.env.REACT_APP_DEFAULT_DEP_USINE);
              if (departData && ondMTData && departData.PT < ondMTData.PT){
                return {
                  ...machine,
                  PL1: 0.0,
                  PL2: 0.0,
                  PL3: 0.0,
                  PT: 0.0,
                  etat: calculateEtat(data),
                  VL1: data.voltage.voltage1,
                  VL2: data.voltage.voltage2,
                  VL3: data.voltage.voltage3,
                  U12: data.voltageC.voltageC1,
                  U23: data.voltageC.voltageC2,
                  U31: data.voltageC.voltageC3,
                  AL1: data.current.current1,
                  AL2: data.current.current2,
                  AL3: data.current.current3,
                  PFL1: data.cosphi.cosphi1,
                  PFL2: data.cosphi.cosphi2,
                  PFL3: data.cosphi.cosphi3,
                  PFM: calculatePfm(data),
                  VLM: calculateVL(data),
                  UM: calculateU(data),
                };
              } else {
                return {
                  ...machine,
                  PL1: data.power.power1,
                  PL2: data.power.power2,
                  PL3: data.power.power3,
                  PT: data.power.power,
                  etat: calculateEtat(data),
                  VL1: data.voltage.voltage1,
                  VL2: data.voltage.voltage2,
                  VL3: data.voltage.voltage3,
                  U12: data.voltageC.voltageC1,
                  U23: data.voltageC.voltageC2,
                  U31: data.voltageC.voltageC3,
                  AL1: data.current.current1,
                  AL2: data.current.current2,
                  AL3: data.current.current3,
                  PFL1: data.cosphi.cosphi1,
                  PFL2: data.cosphi.cosphi2,
                  PFL3: data.cosphi.cosphi3,
                  PFM: calculatePfm(data),
                  VLM: calculateVL(data),
                  UM: calculateU(data),
                };
              }
            } else {
              return {
                ...machine,
                etat: calculateEtat(data),
                VL1: data.voltage.voltage1,
                VL2: data.voltage.voltage2,
                VL3: data.voltage.voltage3,
                U12: data.voltageC.voltageC1,
                U23: data.voltageC.voltageC2,
                U31: data.voltageC.voltageC3,
                AL1: data.current.current1,
                AL2: data.current.current2,
                AL3: data.current.current3,
                PFL1: data.cosphi.cosphi1,
                PFL2: data.cosphi.cosphi2,
                PFL3: data.cosphi.cosphi3,
                PL1: data.power.power1,
                PL2: data.power.power2,
                PL3: data.power.power3,
                PT: data.power.power,
                PFM: calculatePfm(data),
                VLM: calculateVL(data),
                UM: calculateU(data),
              };
            }
          }
          return machine;
        });

        const mergedData = mergeData(machinesDataRef.current, updatedData);
        localStorage.setItem('datas_machines_real_time', JSON.stringify(mergedData));
        setMachinesData(mergedData);
        machinesDataRef.current = mergedData;
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const calculateEtat = (data) => {
    const moy_cosphi = (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3;
    if (moy_cosphi < 0.3) {
      return 0;
    } else if (moy_cosphi >= 0.3 && moy_cosphi <= 1) {
      return 1;
    } else {
      return -1;
    }
  };

  const calculatePfm = (data) => {
    return (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3;
  };
  const calculateVL = (data) => {
    return (data.voltage.voltage1 + data.voltage.voltage2 + data.voltage.voltage3) / 3;
  };
  const calculateU = (data) => {
    return (data.voltageC.voltageC1 + data.voltageC.voltageC2 + data.voltageC.voltageC3) / 3;
  };

  const mergeData = (currentData, newData) => {
    const merged = [...currentData];
    newData.forEach(newItem => {
      const index = merged.findIndex(item => item.machine_id === newItem.machine_id);
      if (index !== -1) {
        merged[index] = newItem;
      } else {
        merged.push(newItem);
      }
    });
    return merged;
  };

  const renderEtatIndicator = (etat) => {
    if (etat === -1) {
      return <div className="wrem-1 h-4 bg-grey br-100"></div>;
    } else if (etat === 1) {
      return <div className="wrem-1 h-4 bg-green br-100"></div>;
    } else {
      return <div className="wrem-1 h-4 bg-danger br-100"></div>;
    }
  };

  const bodyTable = () => {
    
    if (machinesData && machinesData.length > 0) {
      const filteredData = machinesData.filter(machine => category.machines.some(m => m.machine_id === machine.machine_id));
      return filteredData.map((machine, index) => (
        <tr key={index}>
          <td 
            className="hoverable-td"
            onClick={() => {
              handleChange({ value: machine.machine_id, label: machine.machine_name });
              window.scrollTo(0, 0);  // This scrolls the page to the top
            }}          >
            {machine.machine_name}
          </td>
          <td>{renderEtatIndicator(machine.etat)}</td>
          <td>{Math.round(machine.PT * 100) / 100}</td>
          <td>{Math.round(machine.PFM * 100) / 100}</td>
          <td>{Math.round(machine.VLM * 100) / 100}</td>
          <td>{Math.round(machine.UM * 100) / 100}</td>
          {/* <td>{Math.round(machine.VL1 * 100) / 100}</td>
          <td>{Math.round(machine.VL2 * 100) / 100}</td>
          <td>{Math.round(machine.VL3 * 100) / 100}</td> */}
          {/* <td>{Math.round(machine.U12 * 100) / 100}</td>
          <td>{Math.round(machine.U23 * 100) / 100}</td>
          <td>{Math.round(machine.U31 * 100) / 100}</td> */}
          <td>{Math.round(machine.AL1 * 100) / 100}</td>
          <td>{Math.round(machine.AL2 * 100) / 100}</td>
          <td>{Math.round(machine.AL3 * 100) / 100}</td>
          <td>{Math.round(machine.PFL1 * 100) / 100}</td>
          <td>{Math.round(machine.PFL2 * 100) / 100}</td>
          <td>{Math.round(machine.PFL3 * 100) / 100}</td>
          <td>{Math.round(machine.PL1 * 100) / 100}</td>
          <td>{Math.round(machine.PL2 * 100) / 100}</td>
          <td>{Math.round(machine.PL3 * 100) / 100}</td>
        </tr>
      ));
    }
    return null;
  };

  return (
    <div className="row row-sm">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header border-bottom">
            {category.img && (
              <img
                src={`data:${category.img.contentType};base64,${category.img.data}`}
                alt={category.img.name}
                className="icon-img"
              />
            )}
            <h3 className="card-title">{category.categoryName}</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered text-nowrap border-bottom w-100">
                <thead>
                  <tr>
                    <th className="wd-10p border-bottom-0">Machine</th>
                    <th className="wd-15p border-bottom-0">Etat</th>
                    <th className="wd-25p border-bottom-0">PT (W)</th>
                    <th className="wd-25p border-bottom-0">PFM</th>
                    <th className="wd-25p border-bottom-0">VM (V)</th>
                    <th className="wd-25p border-bottom-0">UM (V)</th>
                    {/* <th className="wd-20p border-bottom-0">VL1 (V)</th>
                    <th className="wd-15p border-bottom-0">VL2 (V)</th>
                    <th className="wd-10p border-bottom-0">VL3 (V)</th> */}
                    {/* <th className="wd-10p border-bottom-0">U12 (V)</th>
                    <th className="wd-10p border-bottom-0">U23 (V)</th>
                    <th className="wd-10p border-bottom-0">U31 (V)</th> */}
                    <th className="wd-25p border-bottom-0">AL1 (A)</th>
                    <th className="wd-25p border-bottom-0">AL2 (A)</th>
                    <th className="wd-25p border-bottom-0">AL3 (A)</th>
                    <th className="wd-25p border-bottom-0">PFL1</th>
                    <th className="wd-25p border-bottom-0">PFL2</th>
                    <th className="wd-25p border-bottom-0">PFL3</th>
                    <th className="wd-25p border-bottom-0">PL1 (W)</th>
                    <th className="wd-25p border-bottom-0">PL2 (W)</th>
                    <th className="wd-25p border-bottom-0">PL3 (W)</th>
                  </tr>
                </thead>
                <tbody>
                  {bodyTable()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveDataTable;
