import React, { useState, useEffect, useMemo, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import DatePickerArchive from "../components/DatePickerArchive";
import axios from "axios";
import Pagination from "../components/Pagination";
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import { useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function Archives() {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let defaultValue = "";
  let defaultValueExcel = "";
  const selectAllOption = { label: "Sélectionnez tout", value: "*" };
  const options = [];
  function setOptions() {
    const listMachines = JSON.parse(localStorage.getItem("machines"));
    Object.keys(listMachines).map((index) => {
      var details = {
        value: listMachines[index].machine_id,
        label: listMachines[index].machine_name,
      };
      options.push(details);
    });
  }
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  setOptions();
  const selectAllOptionExcel = { label: "Sélectionnez tout", value: "*" };
  const optionsExcel = [];
  function setOptionsExcel() {
    const listMachines = JSON.parse(localStorage.getItem("machines"));
    Object.keys(listMachines).map((index) => {
      var detailsExcel = {
        value: listMachines[index].machine_id,
        label: listMachines[index].machine_name,
      };
      optionsExcel.push(detailsExcel);
    });
  }

  setOptionsExcel();

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({
    machines: [],
    annee_fin: "",
    mois_fin: "",
    jour_fin: "",
    annee_debut: "",
    mois_debut: "",
    jour_debut: "",
  });
  const [selectedExcel, setSelectedExcel] = useState({
    machines: [],
    annee_fin: "",
    mois_fin: "",
    jour_fin: "",
    annee_debut: "",
    mois_debut: "",
    jour_debut: "",
  });
  const initialVisibleOptions =
    options.length === defaultValue?.length
      ? options
      : [selectAllOption, ...options];
  const [availableOptions, setAvailableOptions] = useState(
    initialVisibleOptions
  );
  const initialVisibleOptionsExcel =
    optionsExcel.length === defaultValueExcel?.length
      ? optionsExcel
      : [selectAllOptionExcel, ...optionsExcel];
  const [availableOptionsExcel, setAvailableOptionsExcel] = useState(
    initialVisibleOptionsExcel
  );

  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const [selectedValuesExcel, setSelectedValuesExcel] =
    useState(defaultValueExcel);
  const [selectedValExcel, setSelectedValExcel] = useState(
    selectedExcel.machines
  );
  const [selectedVal, setSelectedVal] = useState(selected.machines);
  const [selectedDate, setSelectedDate] = useState({
    fin: "",
    debut: "",
  });
  const [selectedDateExcel, setSelectedDateExcel] = useState({
    fin: "",
    debut: "",
  });
  const [data, setData] = useState("");
  const [machine_names, setMachineNames] = useState("");
  const handleChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find(
      (o) => o.value === selectAllOption.value
    );

    const newComponentState = selectAllIsSelected
      ? {
        selectedValues: options,
        availableOptions: [],
      }
      : {
        selectedValues: newSelectedOptions,
        availableOptions: initialVisibleOptions,
      };

    setSelectedValues(newComponentState.selectedValues);
    setAvailableOptions(newComponentState.availableOptions);
    // this.onChange(newComponentState.selectedValues);
  };

  const handleChangeExcel = (newSelectedOptionsExcel) => {
    const selectAllIsSelectedExcel = !!newSelectedOptionsExcel.find(
      (o) => o.value === selectAllOption.value
    );

    const newComponentState = selectAllIsSelectedExcel
      ? {
        selectedValuesExcel: optionsExcel,
        availableOptionsExcel: [],
      }
      : {
        selectedValuesExcel: newSelectedOptionsExcel,
        availableOptionsExcel: initialVisibleOptionsExcel,
      };

    setSelectedValuesExcel(newComponentState.selectedValuesExcel);
    setAvailableOptionsExcel(newComponentState.availableOptionsExcel);
    // this.onChange(newComponentState.selectedValuesExcel);
  };
  const handleDateChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
    if (type == "fin") {
      setSelected((prevState) => ({
        ...prevState,
        annee_fin: year,
        mois_fin: month,
        jour_fin: day,
      }));
    } else {
      setSelected((prevState) => ({
        ...prevState,
        annee_debut: year,
        mois_debut: month,
        jour_debut: day,
      }));
    }
  };

  const handleDateExcelChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    setSelectedDateExcel((prevState) => ({ ...prevState, [type]: datee }));
    if (type == "fin") {
      setSelectedExcel((prevState) => ({
        ...prevState,
        annee_fin: year,
        mois_fin: month,
        jour_fin: day,
      }));
    } else {
      setSelectedExcel((prevState) => ({
        ...prevState,
        annee_debut: year,
        mois_debut: month,
        jour_debut: day,
      }));
    }
  };
  
  const handleSubmit = async (e) => {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/archives/get_archives/${selectedVal}/${selected.mois_debut}/${selected.jour_debut}/${selected.annee_debut}/${selected.mois_fin}/${selected.jour_fin}/${selected.annee_fin}`;

      if (Object.keys(selectedVal).length !== 0) {
          try {
              const response = await axios.get(url, 
                {
                headers: {
                  'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                }
              });
              setData(response.data.data_to_return);
              setMachineNames(response.data.machine_names);
          } catch (error) {
              if (error.response && error.response.status === 404) {
                toast.error("error 404");
              } else {
                  setErrorMessage('An unexpected error occurred');
              }
          } finally {
              setIsLoading(false);
          }
      }
  };

  const handleErrors = (e) => {
    if (selected.machines === "") {
      toast.error("Veuillez sélectionner machine");
    }
    else if (selected.debut === "") {
      toast.error("Veuillez sélectionner date de début");
    }
    else if (selected.fin === "") {
      toast.error("Veuillez sélectionner date de fin");
    }
    else {
      handleSubmit(e);
    }
  }
  const handleExportExcel = async () => {
    const url = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/archives/export_archives_excel/${selectedValExcel}/${selectedExcel.mois_debut}/${selectedExcel.jour_debut}/${selectedExcel.annee_debut}/${selectedExcel.mois_fin}/${selectedExcel.jour_fin}/${selectedExcel.annee_fin}`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob' // Important to receive a Blob object
      });

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'archive.xlsx'); // Set the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid input");
      } else {
        // Handle other errors
        console.error('Error downloading the file:', error.response || error);
      }
    }
  };
  const handleExportExcel2 = async () => {
    const url = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/archives/export_archives_excel/${selectedVal}/${selected.mois_debut}/${selected.jour_debut}/${selected.annee_debut}/${selected.mois_fin}/${selected.jour_fin}/${selected.annee_fin}`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob' // Important to receive a Blob object
      });

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'archive.xlsx'); // Set the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid input");
      } else {
        // Handle other errors
        console.error('Error downloading the file:', error.response || error);
      }
    }
  };

  useEffect(() => {
    setSelectedVal(
      Object.keys(selectedValues).map(
        (element) => selectedValues[element].value
      )
    );
    setSelectedValExcel(
      Object.keys(selectedValuesExcel).map(
        (element) => selectedValuesExcel[element].value
      )
    );
  }, [selectedValues, selectedValuesExcel]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, pageSize]);




  const TableArchive = () => {
    return (
      <div className="card-body">
        <div className="table-responsive">

          <table className="table table-striped table-bordered text-nowrap border-bottom w-100">

            <thead>
              <tr>
                <th key={100}></th>
                {Object.keys(machine_names).map((index) => {
                  return <th key={index}>{machine_names[index]}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(currentTableData).map((key) => {
                  return (
                    <tr key={key}>
                      {Object.keys(currentTableData[key]).map((second_key, i) => {
                        return <td key={i}>{second_key == 0 ? currentTableData[key][second_key]
                          : currentTableData[key][second_key] == -1 ? currentTableData[key][second_key] :
                            currentTableData[key][second_key] + " kwh"}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>

          </table>
          <div className="legend">
            <b>NB : La valeur -1 indique qu'il n'y a pas de données archivées </b>
          </div>
          <div class="container-archive-pagination">
          <label class="label-archive" htmlFor="pageSize">Par Page :</label>
            <div class="form-group-archive">
              <select
                id="pageSize"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                className="form-control"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
              </select>
            </div>
            <div class="pagination-container">
            <label class="label-archive" htmlFor="pageSize">Page </label>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>

        </div>

      </div>

    );
  };
  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="archives" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Archives</h1>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Exportez en EXCEL </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Machines
                      </label>
                      <div className="form-group col-md-10 col-lg-10 col-xl-3">
                        <SelectBox
                          options={availableOptionsExcel}
                          value={selectedValuesExcel}
                          defaultValue={selectedValuesExcel}
                          onChange={handleChangeExcel}
                          placeholder={"Selectionnez machine(s)..."}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>
                      <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                        De
                      </label>
                      <div className="col-md-3 col-lg-4 col-xl-2 ">
                        <DatePickerArchive
                          id={"dateDebut"}
                          value={selectedDateExcel.debut}
                          onChange={(e) => handleDateExcelChange(e, "debut")}
                        />
                      </div>

                      <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                        A
                      </label>
                      <div className="col-md-3 col-lg-4 col-xl-2 ">
                        <DatePickerArchive
                          id={"dateDebut"}
                          value={selectedDateExcel.fin}
                          onChange={(e) => handleDateExcelChange(e, "fin")}
                        />
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <button
                          className="btn btn-outline-green btn-block"
                          onClick={handleExportExcel}
                          >
                          <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">
                      Sélectionnez une ou plusieurs machines
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Machines
                      </label>
                      <div className="form-group col-md-10 col-lg-10 col-xl-3">
                        <SelectBox
                          options={availableOptions}
                          value={selectedValues}
                          defaultValue={selectedValues}
                          onChange={handleChange}
                          placeholder={"Selectionnez machine(s)..."}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>

                      <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                        De
                      </label>
                      <div className="col-md-3 col-lg-3 col-xl-2 ">
                        <DatePickerArchive
                          id={"dateDebut"}
                          value={selectedDate.debut}
                          onChange={(e) => handleDateChange(e, "debut")}
                        />
                      </div>

                      <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                        A
                      </label>
                      <div className="col-md-3 col-lg-3 col-xl-2 ">
                        <DatePickerArchive
                          id={"dateFin"}
                          value={selectedDate.fin}
                          onChange={(e) => handleDateChange(e, "fin")}
                        />
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <button
                          className="btn btn-outline-info btn-block"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-search me-2"></i>
                          Chercher
                        </button>
                        <button
                          className="btn btn-outline-green btn-block"
                          onClick={handleExportExcel2}
                          >
                          <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card custom-card">
                      {isLoading ? (
                        <BouncingDotsLoader />
                      ) : (
                        Object.keys(data).length > 0) ? (
                        TableArchive(data, machine_names)
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
        <Footer />
      </div>
    </>
  );
}
export default Archives;
