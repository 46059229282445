import React, { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function ParamsApp() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    const navigate = useNavigate();
    const [selectedFeature, setSelectedFeature] = useState({
        pression: false,
        debimetre: false,
        energie_cumulee: false,
        factureexcedentPVMT: false,
        facturProductiblePVMT: false,
        prodPvRealTime: false,
        prodPvBtRealTime: false,
        factureBasseTension: false,
    });
    const handleCheckboxChange = (e, type) => {
        const isChecked = e.target.checked;
        setSelectedFeature((prevState) => ({
            ...prevState, [type]: isChecked
        }));
    };


    const handelSubmit = async (e) => {
        toast.success("choix validé")
        setTimeout(() => {
            navigate("/")
        }, 3000);

        try {
            const data = await axios
                .post(process.env.REACT_APP_BACKEND_IP + ':'
                    + process.env.REACT_APP_BACKEND_PORT + '/parametreApp/saveParametreApp/'
                    + selectedFeature.energie_cumulee + '/'
                    + selectedFeature.pression + '/'
                    + selectedFeature.factureexcedentPVMT + '/'
                    + selectedFeature.facturProductiblePVMT + '/'
                    + selectedFeature.prodPvRealTime + '/'
                    + selectedFeature.prodPvBtRealTime + '/'
                    + selectedFeature.factureBasseTension + '/'
                    + selectedFeature.debimetre,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                );
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        async function fetchData() {
            let dataParametreApp;
            try {
                dataParametreApp = await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/parametreApp/getDataParamsApp',
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                )
            } catch (error) {
                console.log(error)
            }
            if (dataParametreApp.data != null) {
                setSelectedFeature(dataParametreApp.data)
            };
        }
        fetchData();
    }, [])

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <div className="main-container container-fluid">
                        <div className="page-header">
                            <div>
                                <h1 className="page-title">Paramètres Application (ADMIN)</h1>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom">
                                <h3 className="card-title">Sélectionnez options à afficher</h3>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="card-header border-bottom border-top">
                                        <h6 className="card-title color_blue">SECTION : GENERAL</h6>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Pression
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchP"
                                            name="switchP"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "pression")}
                                            checked={(selectedFeature.pression === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchP"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                    Debimetre
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchD"
                                            name="switchP"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "debimetre")}
                                            checked={(selectedFeature.debimetre === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchD"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Energie Cumulée
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchE"
                                            name="switchE"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "energie_cumulee")}
                                            checked={(selectedFeature.energie_cumulee === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchE"
                                            className="label-danger"
                                        ></label>
                                    </div>

                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="card-header border-bottom border-top">
                                        <h6 className="card-title color_blue">SECTION : MOYENNE TENSION</h6>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Facture excédent Photovoltaïque  Moyenne Tension
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchFE"
                                            name="switchFE"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "factureexcedentPVMT")}
                                            checked={(selectedFeature.factureexcedentPVMT === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchFE"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Facture productible Photovoltaïque Moyenne tension
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchFPMT"
                                            name="switchFPMT"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "facturProductiblePVMT")}
                                            checked={(selectedFeature.facturProductiblePVMT === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchFPMT"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Production Photovoltaïque en Temps Réel moyenne tension
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchPVrealTime"
                                            name="switchPVrealTime"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "prodPvRealTime")}
                                            checked={(selectedFeature.prodPvRealTime === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchPVrealTime"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="card-header border-bottom border-top">
                                        <h6 className="card-title color_blue">SECTION : BASSE TENSION</h6>
                                    </div>
                                </div>
                                <br></br>
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                        Production Photovoltaïque en Temps Réel basse tension
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchPVBTrealTime"
                                            name="switchPVBTrealTime"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "prodPvBtRealTime")}
                                            checked={(selectedFeature.prodPvBtRealTime === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchPVBTrealTime"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                               
                                <div className='row'>
                                    <label className="form-label col-md-6 col-lg-6 col-xl-6">
                                       Estimation facture basse tension
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-6">
                                        <input
                                            id="checkedDangerSwitchPVBT"
                                            name="switchPVBT"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "factureBasseTension")}
                                            checked={(selectedFeature.factureBasseTension === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchPVBT"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 mt-5 col-lg-2 col-xl-2'>
                                        <button className="btn btn-outline-primary btn-block" type="submit" onClick={(e) => { handelSubmit(e); }}>
                                            <i className="fa fa-bar-chart me-2"></i>Valider
                                        </button>
                                        <ToastContainer
                                            className="toast-container"
                                            autoClose={2000}
                                            closeButton={false}
                                            theme={"colored"}
                                            icon={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ParamsApp
