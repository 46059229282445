import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddCategory() {
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState({
        name_category: "",
        icon_category: null,
    });
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management
   
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file.type === "image/jpeg" || file.type === "image/png") {
          setCategoryData((prevState) => {
            return {
              ...prevState,
              icon_category: file,
            }
          })
        } else {
          toast.warning("Seulement les images de types JPEG ou PNG sont autorisées");
        }
      }

    const handleAdd = async () => {
        const formData = new FormData();
        formData.append("nom", categoryData.name_category);
        formData.append("cat_icon", categoryData.icon_category);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/categories/add`,
                formData,
                {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 201) {
                toast.success("Catégorie ajoutée avec succès");
                setCategoryData({
                    name_category: "",
                    icon_category: null,
                });
                navigate("/gestionCategory");
            }
        } catch (error) {
            toast.error("Erreur lors de l'ajout de la catégorie");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCategoryData({ ...categoryData, [name]: value });
    };

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="gestionCategory" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Ajouter nouvelle catégorie</h1>
                                    </div>
                                </div>

                                <div className="card">
                                    
                                    <div className="card-body">
                                        <label className="form-label col-12"> Nom catégorie </label>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={categoryData.name_category}
                                                name="name_category"
                                                placeholder="Saisir catégorie"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="form-label col-12"> Icon catégorie </label>
                                        <div className="form-group col-12">
                                            <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
                                        </div>
                                       
                                        <div className="row col-12">
                                            <div className="col-12 d-flex flex-column justify-content-between align-items-end" style={{ height: '100%' }}>
                                                <div style={{ flex: 1 }}></div>
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={handleAdd}
                                                >
                                                    Ajouter Catégorie
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ToastContainer
                                    className="toast-container"
                                    autoClose={2000}
                                    closeButton={false}
                                    theme={"colored"}
                                    icon={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default AddCategory;
