import React, { useState } from 'react';
import './EnercoInput.css';

const EnercoInput = ({ label, value, name, type, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`enerco-input-container ${isFocused || value ? 'focused' : ''}`}>
      <input
        className="enerco-input"
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        required
      />
      <label className="enerco-label">{label}</label>
    </div>
  );
};

export default EnercoInput;
