import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import jsPDF from "jspdf";
import { factureProductible } from "../components/factureProductibleImage";
import { useNavigate } from "react-router-dom";

function FactureProductibleMT() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    function truncateToDecimals(num, dec = 2) {
        const calcDec = Math.pow(10, dec);
        return Math.trunc(num * calcDec) / calcDec;
      }


    const navigate = useNavigate();
    const [selected, setSelected] = useState({
        annee_fin: "",
        mois_fin: "",
        jour_fin: "",
        annee_debut: "",
        mois_debut: "",
        jour_debut: "",
        timeDebut: "",
        timeFin: "",
    });

    const [selectedDate, setSelectedDate] = useState({
        fin: "",
        debut: "",
    });
    const [selectedTime, setSelectedTime] = useState({
        timeDebut: "",
        timeFin: "",
    });


    function isLater(dateString1, dateString2) {
        return new Date(dateString1) > new Date(dateString2)
    }

    function isLaterTime(dateString1, dateString2) {
        return dateString1 > dateString2
    }

    const handleErrors = async () => {
        const date_deb = selected.annee_debut + "-" + selected.mois_debut + "-" + selected.jour_debut;
        const date_fin = selected.annee_fin + "-" + selected.mois_fin + "-" + selected.jour_fin;

        if (selected.annee_debut === "") {
            toast.error("Veuillez sélectionner date de début");
        } else if (selected.annee_fin === "") {
            toast.error("Veuillez sélectionner date de fin");
        } else if (isLater(date_deb, date_fin)) {
            toast.error("La date de début doit être anterieur a la date fin");
        } else if (isLaterTime(selected.timeDebut, selected.timeFin)) {
            toast.error("Le temps de début doit être anterieur au temps de fin");
        } else {
            if (selected.timeDebut == "") {

                var body = {
                    year: selected.annee_debut,
                    month: selected.mois_debut,
                    day: selected.jour_debut,
                    year1: selected.annee_fin,
                    month1: selected.mois_fin,
                    day1: selected.jour_fin,
                    time1: "00",
                    time2: "00"
                };
            } else {

                var body = {
                    year: selected.annee_debut,
                    month: selected.mois_debut,
                    day: selected.jour_debut,
                    year1: selected.annee_fin,
                    month1: selected.mois_fin,
                    day1: selected.jour_fin,
                    time1: selected.timeDebut,
                    time2: selected.timeFin
                };
            }

    var time_debut = "";
      var time_fin = "";
      try {
        if(selected.timeDebut=="" ||selected.mois_debut + '/' + selected.jour_debut + '/' + selected.annee_debut != selected.mois_fin + '/' + selected.jour_fin + '/' + selected.annee_fin){
        time_debut = "00:00";
      }else {
        time_debut = selected.timeDebut;
      }
      if(selected.timeFin=="" ||selected.mois_debut + '/' + selected.jour_debut + '/' + selected.annee_debut != selected.mois_fin + '/' + selected.jour_fin + '/' + selected.annee_fin){
        var time = new Date()
        var hour = time.getHours();
        var minutes = time.getMinutes()-1;
        if (hour < 10) {
          hour = "0" + hour
        }
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        var new_time = hour + ':' + minutes
        time_fin  = new_time;
      }
      else {
        time_fin = selected.timeFin;
      }
        
      const response = await axios.get(process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/historique/checkDates/'
        + selected.mois_debut + '/'
        + selected.jour_debut + '/'
        + selected.annee_debut + '/'
        + time_debut + '/'
        + selected.mois_fin + '/'
        + selected.jour_fin + '/'
        + selected.annee_fin + '/'
        + time_fin,
        {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
      );
      if (response.status === 200) {
              try {

                await axios.post(

                    process.env.REACT_APP_BACKEND_IP +
                    ":" +
                    process.env.REACT_APP_BACKEND_PORT +
                    "/factureProductiblePvMt/dataFactureProductible",
                     body,
                     {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                    )
                  .then(res => {
                    if (res.data.status === "Empty"){
                      
                      toast.error("Vous devez sélectionnez votre type facture !")
                      setTimeout(() => {
                        navigate("/paramsFacturProductiblePVMT")
                      }, 3000);
                    }
                    if (res.data.status === "full"){
                    
                      generatePDF(res.data.facture);
                      
                      
                    }
                  })
              } catch (error) {
                console.log(error);
              }
            
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
          const response = error.response.data;
          // Handle validation errors
          if (response.date1IsBeforedate2 === false) {
            toast.error("Le temps de début doit être antérieur au temps de fin");
          } 
          else if (response.date1IsBeforeNow === false) {
            toast.error("Le temps de début doit être antérieur au temps actuelle");
          } else if (response.date2IsBeforeNow === false) {
            toast.error("Le temps de fin doit être antérieur au temps actuelle");
          } else {
            toast.error("Invalid dates provided.");
          }
        } else {
          console.log(error);
          toast.error("Une erreur s'est produite lors de la vérification des dates");
        }
      }
      
      }
    };

    const handleDateChange = (date, type) => {
        var datee = new Date(date);
        var day = datee.getDate();
        var month = datee.getMonth() + 1;
        var year = datee.getFullYear();

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }

        setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
        if (type == "fin") {
            setSelected((prevState) => ({
                ...prevState,
                annee_fin: year,
                mois_fin: month,
                jour_fin: day,
            }));
        } else {
            setSelected((prevState) => ({
                ...prevState,
                annee_debut: year,
                mois_debut: month,
                jour_debut: day,
            }));
        }
    };

    const handleTimeChange = (date, type) => {
        var time = new Date(date);
        var hour = time.getHours();
        var minutes = time.getMinutes();
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        var new_time = hour + ":" + minutes;
        setSelectedTime((prevState) => ({ ...prevState, [type]: time }));
        setSelected((prevState) => ({ ...prevState, [type]: new_time }));
    };

    const dateDebut =
        selected.jour_debut +
        " - " +
        selected.mois_debut +
        " - " +
        selected.annee_debut;
    const dateFin =
        selected.jour_fin + " - " + selected.mois_fin + " - " + selected.annee_fin;
    const tempsDebut= selected.timeDebut

    const tempsFin = selected.timeFin
    const generatePDF = (data) => {
        var doc = new jsPDF();
        var imgData = factureProductible;
        let montant =( data.sum_energie) * (data.parametre.tarif)
        doc.addImage(imgData, "JPEG", 0, 0, 210, 297);
        doc.setFontSize(10);
        doc.setTextColor('#044568')
          doc.text(data.parametre.district, 158, 34);
          doc.text((data.parametre.tel).toString(), 165, 48);
          doc.text((data.parametre.fax).toString(), 102, 48);
          doc.text(data.parametre.rib, 120, 55);
          doc.text(data.parametre.consommateur, 120, 61);
          doc.text(data.parametre.adresseConsommateur, 110, 67);
          doc.text(data.parametre.payeur, 25, 60);
          doc.text(data.parametre.adressePayeur, 29, 67);
        
          doc.text(dateDebut + " -> " + dateFin, 75, 38);
          doc.text(data.parametre.ur, 180, 91);
          doc.text(data.parametre.reference, 160, 91);
          doc.text(data.parametre.codePayeur, 138, 91);
          doc.text(data.parametre.rib, 90, 91);

          doc.text(data.parametre.typeCentrale, 28, 156);
          doc.text((data.parametre.puissanceInstalle).toString(), 105, 156);
          doc.text(data.dateInstall, 155, 156);
          
          doc.text(dateDebut , 43, 182);
          doc.text(dateFin , 89, 182);

          doc.text("Onduleur", 22, 227);
          doc.text("Photovoltaïque", 18, 233);
          doc.text(dateDebut , 44, 230);
          if (tempsDebut == ""){
            doc.text("00:00" , 72, 230);
          }else {
            doc.text(tempsDebut , 72, 230);
          }
        
          doc.text(dateFin , 88, 230);
          if (tempsFin == ""){
            doc.text("23:59" , 116, 230);
          }else {
            doc.text(tempsFin , 116, 230);
          }
          doc.text((truncateToDecimals(data.sum_energie)).toString(), 135, 230);
          doc.text((data.parametre.tarif).toString(), 160, 230);
        
          doc.text((truncateToDecimals(montant)).toString(), 177, 230);

        doc.save("Facture Productible Estimée.pdf");
    };
    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="facturProductiblePVMT" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Estimation production photovoltaïque moyenne tension</h1>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">Générez l'estimation de la production des panneaux photovoltaïques</h3>
                                    </div>
                                    <div className="card-body">

                                        <div className="row">
                                            <label className="form-label col-md-3 col-lg-3 col-xl-1">
                                                Date
                                            </label>
                                            <div className="col-md-4 col-lg-4 col-xl-2 ">
                                                <DatePicker
                                                    id={"dateDebut"}
                                                    value={selectedDate.debut}
                                                    onChange={(e) => handleDateChange(e, "debut")}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-xl-2 ">
                                                <DatePicker
                                                    id={"dateFin"}
                                                    value={selectedDate.fin}
                                                    onChange={(e) => handleDateChange(e, "fin")}
                                                />
                                            </div>

                                            <label className="form-label col-md-3 col-lg-3 col-xl-1">
                                                Temps
                                            </label>
                                            <div className="col-md-4 col-lg-4 col-xl-2 ">
                                                <TimePicker
                                                    id={"timeDebut"}
                                                    value={selectedTime.timeDebut}
                                                    onChange={(e) => handleTimeChange(e, "timeDebut")}
                                                    disabled={
                                                        selected.jour_debut === selected.jour_fin &&
                                                            selected.mois_debut === selected.mois_fin &&
                                                            selected.annee_debut === selected.annee_fin
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-4 col-lg-4 col-xl-2 ">
                                                <TimePicker
                                                    id={"timeFin"}
                                                    value={selectedTime.timeFin}
                                                    onChange={(e) => handleTimeChange(e, "timeFin")}
                                                    disabled={
                                                        selected.jour_debut === selected.jour_fin &&
                                                            selected.mois_debut === selected.mois_fin &&
                                                            selected.annee_debut === selected.annee_fin
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-12 col-lg-12 col-xl-2">
                                                <button
                                                    className="btn btn-outline-danger btn-block"
                                                    type="submit"
                                                    onClick={() => handleErrors()}
                                                >
                                                    <i className="fa fa-file-pdf-o me-1"></i>Génèrez Es.Production
                                                </button>
                                                <ToastContainer
                                                    className="toast-container"
                                                    autoClose={4000}
                                                    closeButton={false}
                                                    theme={"colored"}
                                                    icon={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default FactureProductibleMT;
