import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const isAuthenticated = localStorage.getItem('jwt');

  const decodeToken = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  const isTokenExpired = (token) => {
    const decoded = decodeToken(token);
    if (!decoded) return true; // Consider expired if the token can't be decoded
  
    const now = Date.now() / 1000; // Get current time in seconds
    return decoded.exp < now;
  };
  if (!isAuthenticated || isTokenExpired(isAuthenticated)) {
    // Optionally clear local storage or handle logout
    localStorage.removeItem("jwt");
    localStorage.removeItem("isLoggedIn");
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;