import React, { useState } from 'react';
import './EnercoInput.css'; 
import eyeIcon from '../icons/eye.png';
import eyeSlashIcon from '../icons/eyeslach.png';

const PasswordInput = ({ label, name, value, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={`enerco-input-container ${isFocused || value ? 'focused' : ''}`}>
            <input
                type={showPassword ? 'text' : 'password'}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className="enerco-input"
                required
            />
            <label className="enerco-label">{label}</label>
            <button
                type="button"
                onClick={toggleShowPassword}
                className="toggle-password-button"
            >
                <img 
                    src={showPassword ? eyeSlashIcon : eyeIcon} 
                    alt={showPassword ? "Hide password" : "Show password"} 
                    className="eye-icon"
                />
            </button>
        </div>
    );
};

export default PasswordInput;
