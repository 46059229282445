import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericInput from "react-numeric-input";
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from '../utils/axios';  



function GestionUsine() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const params = useParams();
  const navigate = useNavigate();
  const [errore, setErrore] = useState([]);

  const [data, setData] = useState({
    testImage: "",
    machines: [
      { 
        machineSettings: [
          {
            machine_name: "",
            seuil_usinage_max: 0,
            seuil_usinage_min: 0,
            seuil_reglage_max: 0,
            seuil_reglage_min: 0,
            reglage: false,
          },
          {
            machine_name: "",
            seuil_usinage_max: 0,
            seuil_usinage_min: 0,
            seuil_reglage_max: 0,
            seuil_reglage_min: 0,
            reglage: false,
          },
        ],
      }
    ],
  });

  const { testImage, machines } = data;


  //  const handleChange = (event) => {
  //   const { name, value } = event.target
  //   if (errore.includes(name)) {
  //     setErrore(errore.filter((e) => e !== name))
  //   }
  //   setData((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: value,
  //     }
  //   })

  // }

  //Checkbox handler

  function formatCourant(num) {
    return num + "A";
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type === "image/jpeg" || file.type === "image/png") {
      setData((prevState) => {
        return {
          ...prevState,
          testImage: file,
        };
      });
    } else {
      toast.warning(
        "Seulement les images de types JPEG ou PNG sont autorisées"
      );
    }
  };

  const handleEditSubmit = async () => {
    const formData = new FormData();

    formData.append("testImage", testImage);
    try {
      await axios
        .post(
          process.env.REACT_APP_BACKEND_IP +
            ":" +
            process.env.REACT_APP_BACKEND_PORT +
            "/users/editUsine",
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          },
          {}
        )
        .then((res) => {
          if (res.status == 200 && res.data.status == "LOGO est mise a jour") {
            toast.success(res.data.status);
            window.location.reload();
          }

          if (res.status == 500 && res.data.message == "Error updating usine") {
            toast.error(res.data.status);
          }
        });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
    // }
  };
  const _arrayBufferToBase64 = (buffer) => {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const [usineData, setUsineData] = useState([]);
  useEffect(() => {
    async function fetchparams() {
      let data;
      try {
        data = await axios.get("/users/usine/gestionUsine",
            {
              headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
              }
            }
        );
        setUsineData(data.data.usine);
      } catch (error) {
        console.log(error);
      }
    }
    fetchparams();
  }, []);


  function tableBody(data) {
    if (Object.keys(data).length > 0) {
      return (
        <table
          id="basic-datatable"
          className="table table-bordered table-vcenter text-nowrap mb-0 w-100"
        >
          <thead>
            <tr>
              <th className="border-bottom-0">Nom machine</th>
              <th className="border-bottom-0">Seuil Usinage Max</th>
              <th className="border-bottom-0">Seuil Usinage Min</th>
              <th className="border-bottom-0">Reglage</th>
              <th className="border-bottom-0">Seuil Reglage Max</th>
              <th className="border-bottom-0">Seuil Reglage Min</th>
              <th className="border-bottom-0 w-14">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map(machine => (
              <tr key={machine.machine_id}>
                <td>{machine.machine_name}</td>
                <td>{machine.seuil_usinage_max}</td>
                <td>{machine.seuil_usinage_min}</td>
                <td>{machine.reglage ? "Oui" : "Non"}</td>
                <td>{machine.seuil_reglage_max}</td>
                <td>{machine.seuil_reglage_min}</td>
                <td>
                  <Link to={`/MachineConfig/${machine.machine_id}`}>
                    <button className="btn btn-warning btn-spinners ms-2" type="submit">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return null;
  }


  return (
    <>
      {}
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier LOGO usine </h1>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mb-4">
                          <label className="form-label col-xl-3">Image</label>
                          <div className="col-md-3 col-lg-3 col-xl-3">
                            {usineData?.img?.data?.data ? (
                              <img
                                src={`data:${
                                  usineData.img.contentType
                                };base64,${_arrayBufferToBase64(
                                  usineData.img.data.data
                                )}`}
                                alt=""
                                className="logo-img"
                              />
                            ) : (
                              <img alt="" />
                            )}
                          </div>
                          <div className="form-group col-md-4 col-lg-4 col-xl-4">
                            <input
                              type="file"
                              onChange={handleFileChange}
                              accept=".jpg, .jpeg, .png"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xl-12">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn btn-outline-success btn-block"
                                type="submit"
                                onClick={handleEditSubmit}
                              >
                                Valider
                              </button>
                              <ToastContainer
                                className="toast-container"
                                autoClose={2000}
                                closeButton={false}
                                theme={"colored"}
                                icon={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                        <div className="table-responsive">
                        <LazyLoad once>
                          {usineData.departures && usineData.departures.length > 0 && Object.keys(usineData.departures[0].machines).length > 0 ? (
                            tableBody(usineData.departures[0].machines)
                          ) : (
                            <></>
                          )}
                        </LazyLoad>
                        </div>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">      
                          <label className="form-label  col-xl-3"> Image </label>
                          <div className="col-md-3 col-lg-3 col-xl-3">
                          { usineData?.img?.data?.data ?
                           <img
                            src={`data:${usineData.img.contentType};base64,${_arrayBufferToBase64(usineData.img.data.data)}`}
                            alt=""
                            className="logo-img"
                          />  : <img alt ="" />
                          }
                          </div>
                          <div className="form-group col-md-4 col-lg-4 col-xl-4">
                            <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2">
                          <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <button className="btn btn-outline-success btn-block" type="submit" onClick={handleEditSubmit}>
                              Valider
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default GestionUsine;