import React from 'react';

const NotFoundPage = () => {
    return (
        <div className="container">
            <div className="not-found-container">
            </div>
        </div>
        
    );
};

export default NotFoundPage;
