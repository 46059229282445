import React, { useState, useEffect } from "react";
import LazyLoad from 'react-lazyload';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";


function GestionCompresseur() {
  const [compresseurData, setCompresseurData] = useState([])


  useEffect(() => {
    async function fetchData() {
      let dataCompresseur;
      try {
        const token = localStorage.getItem('jwt'); 
        dataCompresseur = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/pression/getCompresseursList',
          {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
          }
        )
        setCompresseurData(dataCompresseur.data.compresseurs)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
    
  }, [])



  function tableBody(data) {
    if (Object.keys(data).length > 0) {
      return (
        <table
          id="basic-datatable"
          className="table table-bordered table-vcenter text-nowrap mb-0 w-100"
        >
          <thead>
            <tr>
              
              <th className="border-bottom-0">Nom machine</th>
              <th className="border-bottom-0">valeur Max</th>
              <th className="border-bottom-0">N° Responsable</th>
              <th className="border-bottom-0 w-14">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(key => {
              return (
                <tr key={data[key]['id_machine']}>
                  <td>{data[key]['name_machine']}</td>
                  <td>{data[key]['max_value']} BAR</td>
                  <td>{data[key]['numero']}</td>
                  <td>
                    <Link to={"/editCompresseur/"+ data[key].id_machine}>
                      <button className="btn btn-warning btn-spinners ms-2" type="submit">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>
                  </td>
                </tr>
    
              )
            })
            }
          </tbody>
        </table>
      )
    }
  
  }

  return (
    <>

      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="GestionCompresseur" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Gestion Compresseurs</h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">Listes des compresseurs</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                        <LazyLoad once>
                          {
                            Object.keys(compresseurData).length > 0 ?
                              (tableBody(compresseurData))
                              : (<></>)
                          }
                          </LazyLoad>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default GestionCompresseur;
