import React, { useState, useEffect } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import DatePicker from '../components/DatePicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ParametresFactureProductible() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    const [info, setInfo] = useState({
        district: "",
        consommateur: "",
        adresseConsommateur: "",
        payeur: "",
        codePayeur: "",
        ur: "",
        adressePayeur: "",
        reference: "",
        rib: "",
        tel: "",
        fax: "",
        tarif: "",
        typeCentrale: "",
        puissanceInstalle: "",


    })
    const [selectedDate, setSelectedDate] = useState({
        dateInstallation: ""
    });

    const handleDateChange = (date, type) => {
        var datee = new Date(date)
        var day = datee.getDate();
        var month = datee.getMonth() + 1;
        var year = datee.getFullYear();

        if (day < 10) {
            day = "0" + day
        }
        if (month < 10) {
            month = "0" + month
        }

        var new_date = year + '-' + month + '-' + day
        setSelectedDate(prevState => ({ ...prevState, [type]: new_date }));
    };
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setInfo((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    const {
        district,
        consommateur,
        adresseConsommateur,
        payeur,
        codePayeur,
        ur,
        adressePayeur,
        reference,
        rib,
        tel,
        fax,
        tarif,
        typeCentrale,
        puissanceInstalle
    } = info

    const handleTarifSubmit = async (e) => {
        e.preventDefault();
        let data_added;
        data_added =
        {
            "district": district,
            "consommateur": consommateur,
            "adresseConsommateur": adresseConsommateur,
            "payeur": payeur,
            "codePayeur": codePayeur,
            "ur": ur,
            "adressePayeur": adressePayeur,
            "reference": reference,
            "rib": rib,
            "tel": tel,
            "fax": fax,
            "tarif": tarif,
            "typeCentrale": typeCentrale,
            "puissanceInstalle": puissanceInstalle,
            "dateInstallation": selectedDate.dateInstallation
        }

        if (district == "" || consommateur == "" || adresseConsommateur == "" || payeur == "" || codePayeur == ""
            || reference == "" || rib == "" || tarif == null || typeCentrale == "" || puissanceInstalle == null || dateInstallation == undefined
            || tel == "" || fax == "") {
            toast.error("les paramètres sont obligatoires !")
        }
        else {
            try {
                await axios
                    .post(process.env.REACT_APP_BACKEND_IP
                        + ':' + process.env.REACT_APP_BACKEND_PORT
                        + '/factureProductiblePvMt/saveParametreFactureProductibleMt/', data_added,
                        {
                            headers: {
                              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                            }
                          }
                    )
                    .then((response) => console.log("response data from axios post data", response.data))

                toast.success("Vos paramètres ont été ajoutés avec succès")
            } catch (error) {
                console.log(error)
                toast.error("les paramètres sont obligatoires !")
            }
        }

    }

    async function fetchData() {
        let dataParamsFacture;
        try {
            dataParamsFacture = await axios.get(process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/factureProductiblePvMt/getParamterFactureProductibleMt/',
                {
                    headers: {
                      'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                    }
                  }
            )
        } catch (error) {
            console.log(error)
        }
        if (dataParamsFacture && dataParamsFacture.data) {
        setSelectedDate({ dateInstallation: dataParamsFacture.data.dateInstallation });
        setInfo({
            district: dataParamsFacture.data.district,
            consommateur: dataParamsFacture.data.consommateur,
            adresseConsommateur: dataParamsFacture.data.adresseConsommateur,
            payeur: dataParamsFacture.data.payeur,
            codePayeur: dataParamsFacture.data.codePayeur,
            ur: dataParamsFacture.data.ur,
            adressePayeur: dataParamsFacture.data.adressePayeur,
            reference: dataParamsFacture.data.reference,
            rib: dataParamsFacture.data.rib,
            tel: dataParamsFacture.data.tel,
            fax: dataParamsFacture.data.fax,
            tarif: dataParamsFacture.data.tarif,
            typeCentrale: dataParamsFacture.data.typeCentrale,
            puissanceInstalle: dataParamsFacture.data.puissanceInstalle,
        })
    }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="paramsFacturProductiblePVMT" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Paramètres facture productible photovoltaïque moyenne tension</h1>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">Paramètres facture productible </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-6  col-xl-6">
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> District </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={district}
                                                            name="district"
                                                            placeholder="Saisir votre district..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Consommateur </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={consommateur}
                                                            name="consommateur"
                                                            placeholder="Saisir nom consommateur..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Adresse consommateur </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={adresseConsommateur}
                                                            name="adresseConsommateur"
                                                            placeholder="Saisir votre adresse consommateur..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Payeur </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={payeur}
                                                            name="payeur"
                                                            placeholder="Saisir nom payeur... "
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                              
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Code Payeur (CP) </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={codePayeur}
                                                            name="codePayeur"
                                                            placeholder="Saisir CP... "
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <label className="form-label col-md-4 col-lg-4 col-xl-4"> Unité Responsable (UR) </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-2">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={ur}
                                                            name="ur"
                                                            placeholder="UR..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Adresse payeur </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={adressePayeur}
                                                            name="adressePayeur"
                                                            placeholder="Saisir votre adresse payeur..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Réfèrence </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={reference}
                                                            name="reference"
                                                            placeholder="Saisir réfèrence..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> RIB Payeur </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={rib}
                                                            name="rib"
                                                            placeholder="Saisir votre RIB..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> N° Télèphone </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={tel}
                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                            placeholder="Saisir votre Tel..."
                                                            name="tel"
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <label className="form-label col-md-4 col-lg-4 col-xl-1"> Fax </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={fax}
                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                            name="fax"
                                                            placeholder="Saisir votre Fax..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3">Tarif</label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <NumericInput
                                                            className="form-control"
                                                            value={tarif}
                                                            name="tarif"
                                                            min={0}
                                                            step={0.001}
                                                            maxLength={10}
                                                            onChange={tarif => handleChange({ target: { value: tarif, name: 'tarif' } })}

                                                        />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <div className="card-header border-bottom border-top">
                                                        <h6 className="card-title color_blue">Identification Centrale Photovoltaïque</h6>
                                                    </div>
                                                </div>
                                                <br></br>

                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Type Centrale </label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={typeCentrale}
                                                            name="typeCentrale"
                                                            placeholder="Saisir type..."
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3">Puissance Installée (kWc)</label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <NumericInput
                                                            className="form-control"
                                                            value={puissanceInstalle}
                                                            name="puissanceInstalle"
                                                            min={0}
                                                            step={0.001}
                                                            maxLength={10}
                                                            onChange={puissanceInstalle => handleChange({ target: { value: puissanceInstalle, name: 'puissanceInstalle' } })}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3">Date Installation</label>
                                                    <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                        <DatePicker
                                                            id={"dateInstallation"}
                                                            value={selectedDate.dateInstallation != undefined ? selectedDate.dateInstallation : ""}
                                                            onChange={(e) => handleDateChange(e, 'dateInstallation')}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                                    <div className="'col-md-12 col-lg-12 col-xl-4">
                                                        <button
                                                            className="btn btn-outline-info btn-block"
                                                            type="submit"
                                                            onClick={handleTarifSubmit}
                                                        >
                                                            <i className="fa fa-calculator me-2"></i>
                                                            Valider
                                                        </button>
                                                        <ToastContainer
                                                            className="toast-container"
                                                            autoClose={4000}
                                                            closeButton={false}
                                                            theme={"colored"}
                                                            icon={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );


}
export default ParametresFactureProductible