import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Chart from "react-apexcharts";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import SelectOne from "../components/SelectOne";
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";
import ReactHighcharts from 'react-highcharts/ReactHighstock.src';
import Highcharts from 'highcharts/highstock';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

function Rapports() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const lignes = [
    {
      label: "Tous",
      value: "all",
    },
    {
      label: "L1",
      value: "l1",
    },
    {
      label: "L2",
      value: "l2",
    },
    {
      label: "L3",
      value: "l3",
    },
  ];
  const RmsTypes = [
    {
      label: "Energie",
      value: "energy"
    },
    {
      label: "Tension",
      value: "tension"
    },
    {
      label: "Courant",
      value: "courant"
    },
    {
      label: "Facteur de puissance",
      value: "cosphi"
    },
    {
      label: "Tension Composée",
      value: "tensionC"
    },
    {
      label: "Puissance",
      value: "puissance"
    },
    {
      label: "Puissance  Apparente",
      value: "puissanceA"
    },
    {
      label: "Puissance Réactive",
      value: "puissanceR"
    },

  ];
  const [rms, setRms] = useState([])
  const [machinesList, setMachinesList] = useState([]);
  const [ligne, setLigne] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [dateEnregistrement, setDateEnregistrement] = useState({
    debut: "",
    fin: "",
  });
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [selectedTime, setSelectedTime] = useState({
    timeDebut: "",
    timeFin: "",
  });

  const [selectedEntregistrement, setSelectedEnregistrement] = useState({
    lignes: "",
    rms: "",
    machine: "",
    annee: "",
    mois: "",
    jour: "",
    annee1: "",
    mois1: "",
    jour1: "",
    timeDebut: "",
    timeFin: "",
  });
  const [lineChartData, setLineChartData] = useState("");
  const [chartTimes, setChartTimes] = useState("");
  const [sumEnergy, setSumEnergy] = useState("");
  const [array, setArray] = useState([])

  const handleCard = () => {
    setShowCard(true);
  };
  let defaultValue = "";
  const selectAllOption = { label: "Sélectionnez tout", value: "*" };
  const options = [];
  function setOptions() {
    const listMachines = JSON.parse(localStorage.getItem("machines"));
    Object.keys(listMachines).map((index) => {
      var details = {
        value: listMachines[index].machine_id,
        label: listMachines[index].machine_name,
      };
      options.push(details);
    });
  }
  setOptions();
  const [selected, setSelected] = useState({
    machines: [],
    annee: "",
    mois: "",
    jour: "",
  });

  const initialVisibleOptions =
    options.length === defaultValue?.length
      ? options
      : [selectAllOption, ...options];
  const [availableOptions, setAvailableOptions] = useState(
    initialVisibleOptions
  );
  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const [selectedVal, setSelectedVal] = useState(selected.machines);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find(
      (o) => o.value === selectAllOption.value
    );

    const newComponentState = selectAllIsSelected
      ? {
        selectedValues: options,
        availableOptions: [],
      }
      : {
        selectedValues: newSelectedOptions,
        availableOptions: initialVisibleOptions,
      };

    setSelectedValues(newComponentState.selectedValues);
    setAvailableOptions(newComponentState.availableOptions);
  };
  const handleTypesChange = (event, type) => {
    setSelectedEnregistrement((prevState) => ({
      ...prevState,
      [type]: event.value,
    }));
  };
  const handleDateChange = (date) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    setSelectedDate(datee);
    setSelected((prevState) => ({
      ...prevState,
      annee: year,
      mois: month,
      jour: day,
    }));
  };
  const handleDateEntregistrementChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    var new_date = year + '-' + month + '-' + day
    var new_date2 = month + '/' + day + '/' + year
    setDateEnregistrement(prevState => ({ ...prevState, [type]: datee }));
    setSelectedEnregistrement(prevState => ({ ...prevState, [type]: new_date }));
    setSelectedEnregistrement(prevState => ({ ...prevState, ['date_' + type]: new_date2 }));
  };
  const handleTimeChange = (date, type) => {
    var time = new Date(date);
    var hour = time.getHours();
    var minutes = time.getMinutes();
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var new_time = hour + ":" + minutes;
    setSelectedTime((prevState) => ({ ...prevState, [type]: time }));
    setSelectedEnregistrement((prevState) => ({
      ...prevState,
      [type]: new_time,
    }));
  };
  const ChercherSubmit = async (e) => {
    let chartData;
    let time_debut = "";
    let time_fin = "";
    
    try {
        if (selectedEntregistrement.timeDebut === "") {
            time_debut = "00:00";
        } else {
            time_debut = selectedEntregistrement.timeDebut;
        }

        if (selectedEntregistrement.timeFin === "") {
            const now = new Date();
            let hour = now.getHours();
            let minutes = now.getMinutes() - 1;
            if (hour < 10) hour = "0" + hour;
            if (minutes < 10) minutes = "0" + minutes;
            time_fin = `${hour}:${minutes}`;
        } else {
            time_fin = selectedEntregistrement.timeFin;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/historique/checkDates/${selectedEntregistrement.date_debut}/${time_debut}/${selectedEntregistrement.date_fin}/${time_fin}`, {
            headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
        });

        if (response.status === 200) {
            if (Object.keys(selectedEntregistrement.lignes).length !== 0 && Object.keys(selectedEntregistrement.machine).length !== 0 && dateEnregistrement !== null) {
                if (selectedEntregistrement.timeDebut !== "" && selectedEntregistrement.timeFin !== "") {
                    try {
                        chartData = await axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/enregistrement_time_chart/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.timeDebut}/${selectedEntregistrement.fin}/${selectedEntregistrement.timeFin}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                            }
                        });

                        const { chart, times, sum_energy, max, min } = chartData.data.data_to_return;
                        const chartTimes = times.map(time => new Date(time).getTime());
                        const lineChartData = chart.map(series => ({
                            name: series.name,
                            data: series.data.map((value, index) => [chartTimes[index], value]) 
                        }));

                        setLineChartData(lineChartData);
                        setChartTimes(chartTimes);
                        setSumEnergy(sum_energy);
                        setMin(min);
                        setMax(max);
                        setArray("");
                    } catch (error) {
                        console.log(error);
                    }
                    handleCard();
                } else {
                    try {
                        chartData = await axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/enregistrement_history_chart/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.fin}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                            }
                        });

                        const { chart, times, sum_energy, max, min } = chartData.data.data_to_return;
                        const chartTimes = times.map(time => new Date(time).getTime());
                        const lineChartData = chart.map(series => ({
                            name: series.name,
                            data: series.data.map((value, index) => [chartTimes[index], value]) // Combine time and data
                        }));
                        setLineChartData(lineChartData);
                        setChartTimes(chartTimes);
                        setSumEnergy(sum_energy);
                        setMin(min);
                        setMax(max);
                        setArray("");
                    } catch (error) {
                        console.log(error);
                    }
                    handleCard();
                }
            } else {
                console.log("Data unavailable");
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const response = error.response.data;
            console.log("Error in data validation");
            // Handle validation errors
            if (response.date1IsBeforedate2 === false) {
                toast.error("Le temps de début doit être antérieur au temps de fin");
            } else if (response.date1IsBeforeNow === false) {
                toast.error("Le temps de début doit être antérieur au temps actuelle");
            } else if (response.date2IsBeforeNow === false) {
                toast.error("Le temps de fin doit être antérieur au temps actuelle");
            } else {
                toast.error("Invalid dates provided.");
            }
        } else {
            console.log(error);
            toast.error("Une erreur s'est produite lors de la vérification des dates");
        }
    }
};

  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  };

  const handleExportToExcel = async () => {
    const url = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/export_energy_to_excel/${selectedVal}/${selected.annee}/${selected.mois}/${selected.jour}/${selected.annee}/${selected.mois}/${selected.jour}`;
    const today = new Date(); 
    const formattedDate = formatDate(today); 
    const fileName = `Rapport_énergétique_${formattedDate}.xlsx`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob' 
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode.removeChild(fileLink);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid input");
      } else {
        // Handle other errors
        console.error('Error downloading the file:', error.response || error);
      }
    }
  };

  const handleExportToPDF = async () => {
    const url = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/export_energy_to_pdf/${selectedVal}/${selected.annee}/${selected.mois}/${selected.jour}/${selected.annee}/${selected.mois}/${selected.jour}`;
    const today = new Date(); // Or use a selected date
    const formattedDate = formatDate(today); // Format today's date
    const fileName = `Rapport_énergétique_${formattedDate}.pdf`;
    try {
      const response = await axios.get(url,
        {
          headers: {
            'Authorization': `Bearer ${token}` 
          },
          responseType: 'blob' 
        });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode.removeChild(fileLink);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid input");
      } else {
        // Handle other errors
        console.error('Error downloading the file:', error.response || error);
      }
    }
  };

  const handleExportClick = async () => {
    const basePath = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}`;

    let url;

    if (selectedEntregistrement.timeDebut === "") {
      url = `${basePath}/rapport/enregistrement_history/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.fin}`;
    } else {
      url = `${basePath}/rapport/enregistrement_time/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.timeDebut}/${selectedEntregistrement.fin}/${selectedEntregistrement.timeFin}`;
    }
    const today = new Date(); // Or use a selected date
    const formattedDate = formatDate(today); // Format today's date
    const fileName = `Rapport_énergétique_${formattedDate}.xlsx`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob' // Important to receive a Blob object
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode.removeChild(fileLink);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid input");
      } else {
        // Handle other errors
        console.error('Error downloading the file:', error.response || error);
      }
    }
  };

  useEffect(() => {
    setMachinesList(JSON.parse(localStorage.getItem("machines")));
    setLigne(lignes);
    setRms(RmsTypes)
    setSelectedVal(
      Object.keys(selectedValues).map(
        (element) => selectedValues[element].value
      )
    );
  }, [selectedValues, lineChartData, chartTimes, selected]);
  const dateFormat = (format) => {
    if (format != undefined) {
      const date = new Date(format);

      //extract the parts of the date
      let day = date.getDate();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      const year = date.getFullYear();
      let string = date.toISOString();
      let time = string.match(/\d\d:\d\d/);
      return day + "-" + month + "-" + year + " " + time;
    }

  }

  const ChartCard = () => {
   

    const handlePointClick = (event) => {

      let power = [];
      const rms = selectedEntregistrement.rms;
      const lignes = selectedEntregistrement.lignes;

      switch (rms) {
        case 'puissance':
          power = lignes === 'all' ? ['P1', 'P2', 'P3'] : [lignes.toUpperCase()];
          break;
        case 'energy':
          power = lignes === 'all' ? ['E1', 'E2', 'E3', 'Total'] : [lignes.toUpperCase()];
          break;
        case 'tension':
          power = lignes === 'all' ? ['V1', 'V2', 'V3'] : [lignes.toUpperCase()];
          break;
        case 'tensionC':
          power = lignes === 'all' ? ['U1', 'U2', 'U3'] : [lignes.toUpperCase()];
          break;
        case 'courant':
          power = lignes === 'all' ? ['A1', 'A2', 'A3'] : [lignes.toUpperCase()];
          break;
        case 'cosphi':
          power = lignes === 'all' ? ['PF1', 'PF2', 'PF3'] : [lignes.toUpperCase()];
          break;
        case 'puissanceR':
          power = lignes === 'all' ? ['PR1', 'PR2', 'PR3'] : [lignes.toUpperCase()];
          break;
        case 'puissanceA':
          power = lignes === 'all' ? ['PA1', 'PA2', 'PA3'] : [lignes.toUpperCase()];
          break;
        default:
          break;
      }

      if (event.point && event.point.index !== undefined) {
        
        const index = event.point.index;
        if (index >= 0 && index < chartTimes.length) {
          const all_data = power.map((p, i) => {
            const value = lineChartData[i]?.data[index];
            const time = chartTimes[index];    
            return [p, value !== undefined ? value : 'N/A', time];
          });
    
          setArray(all_data);
        } else {
          console.error("Invalid index:", index);
        }
      } else {
        console.error("Invalid event.point or event.point.index:", event.point);
      }
    };

    const config = {
      chart: {
        type: 'line',
      },
      xAxis: {
        type: 'datetime',
      },
      tooltip: {
        xDateFormat: '%d/%m/%Y %H:%M',
        shared: true,
        pointFormat: '{point.y:.3f}',
      },
      plotOptions: {
        series: {
          events: {
            click: handlePointClick
          }
        }
      },
      series: lineChartData.map(series => ({
        ...series,
      }))
    };
    
    return (
      <>
        <div className="card">
          {array.length > 0 && (
            <div>
              {Object.keys(array).some(index => !["E1", "E2", "E3", "Total"].includes(array[index][0])) && (
                <div>
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Min & Max pour chaque ligne</h3>
                  </div>
                  <div className="table-responsive export-table">
                    <table className="table table-striped table-bordered text-nowrap key-buttons border-bottom w-100">
                      <thead>
                        <tr>
                          <th className="border-bottom-0">Voie</th>
                          <th className="border-bottom-0">Min</th>
                          <th className="border-bottom-0">Max</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(array)
                          .filter(index => !["E1", "E2", "E3", "Total"].includes(array[index][0]))
                          .map(index => (
                            <tr key={index}>
                              <td>{array[index][0]}</td>
                              <td>{min[index]}</td>
                              <td>{max[index]}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="card-header border-bottom">
                <h3 className="card-title">{sumEnergy ? `Total énergie : ${sumEnergy} Kwh` : "Données au Curseur"}</h3>
              </div>

              <div className="table-responsive export-table">
                <table className="table table-striped table-bordered text-nowrap key-buttons border-bottom w-100">
                  <thead>
                    <tr>
                      <th className="border-bottom-0">Voie</th>
                      <th className="border-bottom-0">Valeur</th>
                      <th className="border-bottom-0">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {array.map((item, index) => (
                      <tr key={index}>
                        <td>{item[0]}</td>
                        <td>{item[1][1]}</td>
                        <td>{dateFormat(item[1][0])}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <ReactHighcharts config={config} />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="rapports" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Rapports & Analyse</h1>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Rapport énergétique</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Machines
                      </label>
                      <div className="form-group col-md-10 col-lg-10 col-xl-4">
                        <SelectBox
                          options={availableOptions}
                          value={selectedValues}
                          defaultValue={selectedValues}
                          onChange={handleChange}
                          placeholder={"Selectionnez machine(s)..."}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>

                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Date
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <DatePicker
                          id={"dateDebut"}
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <button
                          className="btn btn-outline-green btn-block"
                          onClick={handleExportToExcel}
                        >
                          <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                        </button>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <button
                          className="btn btn-outline-danger btn-block"
                          onClick={handleExportToPDF}
                        >
                          <i className="fa fa-file-pdf-o me-2"></i>Export PDF
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card custom-card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">Analyse énergétique</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <label className="form-label col-md-1 col-lg-1 col-xl-1">
                            Machine
                          </label>
                          <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <SelectOne
                              placeholder={"Sélectionnez machine ..."}
                              options={machinesList.map((element) => ({
                                value: element.machine_id,
                                label: element.machine_name,
                              }))}
                              onChange={(e) => handleTypesChange(e, "machine")}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              autosize={true}
                            />
                          </div>

                          <label className="form-label col-md-1 col-lg-1 col-xl-1">
                            RMS
                          </label>
                          <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <SelectOne
                              placeholder={"Sélectionnez RMS ..."}
                              options={
                                rms.map(element => (
                                  { value: element.value, label: element.label }
                                )
                                )}
                              onChange={(e) => handleTypesChange(e, 'rms')}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              autosize={true}
                            />
                          </div>
                          <label className="form-label col-md-1 col-lg-1 col-xl-1">
                            Ligne
                          </label>
                          <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <SelectOne
                              placeholder={"Sélectionnez ligne ..."}
                              options={lignes.map((element) => ({
                                value: element.value,
                                label: element.label,
                              }))}
                              onChange={(e) => handleTypesChange(e, "lignes")}
                              menuPlacement="auto"
                              menuPosition="fixed"
                              autosize={true}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-md-2 col-lg-2 col-xl-1">
                            Date De
                          </label>
                          <div className="col-md-3 col-lg-3 col-xl-2 ">
                            <DatePicker
                              id={"dateEnregistrementDebut"}
                              value={dateEnregistrement.debut}
                              onChange={(e) => handleDateEntregistrementChange(e, 'debut')}
                            />
                          </div>
                          <label className="form-label col-md-2 col-lg-2 col-xl-1">
                            A
                          </label>
                          <div className="col-md-3 col-lg-3 col-xl-2 ">
                            <DatePicker
                              id={"dateEnregistrementFin"}
                              value={dateEnregistrement.fin}
                              onChange={(e) => handleDateEntregistrementChange(e, 'fin')}
                            />
                          </div>
                          <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                            De
                          </label>
                          <div className="col-md-3 col-lg-3 col-xl-2 ">
                            <TimePicker
                              id={"timeDebut"}
                              value={selectedTime.timeDebut}
                              onChange={(e) => handleTimeChange(e, "timeDebut")}
                            />
                          </div>

                          <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                            A
                          </label>
                          <div className="col-md-3 col-lg-3 col-xl-2 ">
                            <TimePicker
                              id={"timeFin"}
                              value={selectedTime.timeFin}
                              onChange={(e) => handleTimeChange(e, "timeFin")}
                            />
                          </div>

                        </div>
                        <div className="btn-list text-center mt-5">
                          <button
                            className="btn btn-outline-info"
                            type="submit"
                            onClick={(e) => ChercherSubmit()}
                          >
                            <i className="fa fa-search me-2"></i>Chercher
                          </button>
                          <button className="btn btn-outline-green" onClick={handleExportClick}>
                            <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                          </button>
                        </div>
                        {showCard ? <ChartCard /> : <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          className="toast-container"
          autoClose={4000}
          closeButton={false}
          theme={"colored"}
          icon={true}
        />
        <Footer />
      </div>
    </>
  );
}
export default Rapports;
