import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Verification() {
  const { token } = useParams();  // Assuming your URL is something like /verification/:token
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyEmail() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/verify-email/${token}`);
        if (response.data) {
          toast.success("Email vérifié avec succès");
          navigate("/login");  // Redirect to dashboard after successful verification
        }
      } catch (error) {
        if (error.response) {
          // Handling errors returned from the backend
          toast.error(error.response.data.message);
        } else {
          toast.error("Une erreur s'est produite lors de la vérification.");
        }
        console.error("Erreur de vérification:", error);
      }
    }
    if (token) {
      verifyEmail();
    } else {
      toast.error("Aucun jeton de vérification trouvé.");
      navigate("/register");  // Redirect somewhere else if no token is provided
    }
  }, [token, navigate]);  // Adding navigate to the dependency array

  return (
    <>
      <ToastContainer />
      <div className="page">
        Verifying...
      </div>
    </>
  );
}

export default Verification;
