import React, { useState } from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        "& .MuiInputBase-root": {
            color: "rgba(73, 80, 87, 0.75)",
            "background-color": "#fff",
            border: "1px solid #eaedf1",
            "border-radius": "7px",
            "font-size": "0.875rem",
            "font-weight": "400",
            "padding": "2px 10px 1px",
            "height": "39px",
        }
    }
});

function TimePicker(props) {
    const classes = useStyles();
    const now = new Date();
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const handleTimeChange = (time) => {
        const selectedTime = new Date(time);
        // if (selectedTime > now) {
        //     setError(true);
        //     setHelperText("Le temps futur n'est pas autorisé.");
        //     return;
        // }
        setError(false);
        setHelperText("");
        props.onChange(time);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                id={props.id}
                error={error}
                helperText={helperText}
                clearable
                className={classes.root}
                value={props.value}
                onChange={handleTimeChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
}

export default TimePicker;
