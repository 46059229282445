import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditCategory() {
  const token = localStorage.getItem('jwt');

  const params = useParams()
  const id = params.id
  const navigate = useNavigate();
  const [errore, setErrore] = useState([]);
  const [dataCategory, setDataCategory] = useState({
    name_category: '',
    old_name_category: "",
    icon_category: '',
    id: id
  });

  const {
    name_category,
    icon_category,
    old_name_category,

  } = dataCategory


  const handleChange = (event) => {
    const { name, value } = event.target
    if (errore.includes(name)) {
      setErrore(errore.filter((e) => e !== name))
    }
    setDataCategory((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })

  }



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type === "image/jpeg" || file.type === "image/png") {
      setDataCategory((prevState) => {
        return {
          ...prevState,
          icon_category: file,
        }
      })
    } else {
      toast.warning("Seulement les images de types JPEG ou PNG sont autorisées");
    }
  }

  const handleEditSubmit = async () => {
    const formData = new FormData()
    formData.append('newNom', name_category)
    formData.append('nom', old_name_category)
    formData.append('cat_icon', icon_category)

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/categories/update/'
        , formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
          }
        },
        {
        }).then(res => {
          if (res.status == 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/gestionCategory")
            }, 3000);
          }
        })

    } catch (error) {
      console.log(error)
    }
  
  }

  const _arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  useEffect(() => {
    async function fetchparams() {
      let paramsCategory;
      try {
        paramsCategory = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/categories/categorie/'
          + id,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        )
        const { data } = paramsCategory;
      setDataCategory((prevState) => ({
        ...prevState,
        name_category: data.nom,
        old_name_category: data.nom,
        icon_category: data.img
        ? {
            contentType: data.img.contentType,
            data: _arrayBufferToBase64(data.img.data.data),
          }
        : null,
        
        }))


      } catch (error) {
        console.log(error)
      }
    }
    fetchparams();
  }, [])


  return (
    <>
      { }
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionCategory" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier Catégorie </h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">{"Catégorie : " + name_category}</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <label className="form-label col-md-2 col-lg-2 col-xl-2"> Nom Catégorie </label>
                          <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <input
                              type="text"
                              className="form-control"
                              value={name_category}
                              name="name_category"
                              placeholder="Saisir catégorie..."
                              onChange={handleChange}
                            />
                          </div>
                          <div className="row align-items-center">
  <label className="form-label col-md-2 col-lg-2 col-xl-2"> Icon Catégorie </label>
  {icon_category && icon_category.data ? (
    <div className="col-md-3 col-lg-3 col-xl-3">
      <img
        src={`data:${icon_category.contentType};base64,${icon_category.data}`}
        alt=""
        className="icon-img"
      />
    </div>
  ) : null}
  <div className="form-group col-md-3 col-lg-3 col-xl-3">
    <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
  </div>
</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                          <div className="'col-md-12 col-lg-12 col-xl-2">
                            <button className="btn btn-outline-warning btn-block" type="submit" onClick={handleEditSubmit}>
                              Modifier
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default EditCategory;
