// src/utils/axios.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}`,
});

axiosInstance.interceptors.response.use(
    response => response,  // This function handles the response if it's successful
    error => {
      // Handle 401 Unauthorized responses
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.clear();  // Optional: Clear local storage or specific tokens
        window.location.href = '/login';  // Redirect to login
      }
      return Promise.reject(error);
    }
  );

export default axiosInstance;
