import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import axios from "axios";
import { io } from 'socket.io-client';


function truncateToDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }
function ProdPvRealTime() {
    const [dataEnergieCumulee , setDataEnergieCumulee] = useState()
    const [dataEnergieCumuleeTGBT , setDataEnergieCumuleeTGBT] = useState()

    const getDataFromSocket = async (data) => { 
        let dataOnduleurPV
        let dataTGBTPV
        try {
            dataOnduleurPV = await axios.get(
                process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/dataPv/getEnergyPVById/' + process.env.REACT_APP_DEFAULT_OND_MT)
                setDataEnergieCumulee(dataOnduleurPV)

                dataTGBTPV = await axios.get(
                    process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/dataPv/getEnergyPVById/' + process.env.REACT_APP_DEFAULT_DEP_USINE)
                    setDataEnergieCumuleeTGBT(dataTGBTPV)
                   
        } catch (error) {
            console.log(error)
        }
    
    }

    useEffect(() => {
      
            const socket = io(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/`);
            socket.on("data", (data) => {
            if(data.machine.id_machine.includes("ond")){
                getDataFromSocket(data)            
            }
            });
            return () => {
                socket.disconnect();
              };
      
      }, []);
    return (
        <>
            <div className="page">
                <div className="page-main container-fluid-prod-pv">
                    <Header />
                    <Sidebar activeItem="prodPvRealTime" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Production Photovoltaîque Moyenne Tension en Temps Réel</h1>
                                    </div>
                                </div>
                                <div className="card-container">
                                    
                                    <div className="card-pv left">
                                        
                                        <div className="left-style">
                                        <div className="image-container p-3">
                                                <div className="overlay-text-compteur">{dataEnergieCumulee != undefined ? truncateToDecimals(dataEnergieCumulee.data.valeur) : ""}</div>
                                                <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/compteur.png"} />
                                            
                                            </div>
                                            <h3>Production <span class="kwh-green">kWh</span></h3>
                                            <div className="image-container p-2">
                                                <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/bouton-vert.png"} />
                                                <div className="overlay-text-card">{dataEnergieCumulee != undefined ? truncateToDecimals((dataEnergieCumulee.data.valeur) * 0.291) : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-comparaison">
                                        { (dataEnergieCumuleeTGBT != undefined && dataEnergieCumulee != undefined) ?
                                        
                                        (dataEnergieCumuleeTGBT.data.valeur) >= (dataEnergieCumulee.data.valeur) ?
                                            <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/inférieur.png"} />
                                        :
                                        <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/supérieur.png"} />
                                        : <></>
                                        }
                                    </div>
                                    <div className="card-pv right"> 
                                        <div className="right-style">
                                            <div className="image-container  p-3">
                                                <div className="overlay-text-compteur">{dataEnergieCumuleeTGBT != undefined ? truncateToDecimals(dataEnergieCumuleeTGBT.data.valeur) : ""}</div>
                                                <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/compteur.png"} />
                                               
                                            </div>                                        
                                            <h3>Consommation<span class="kwh-orange">kWh</span></h3>
                                            
                                            <div className="image-container p-2">
                                                <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/bouton-orange.png"}/>
                                                <div className="overlay-text-card">{dataEnergieCumuleeTGBT != undefined ? truncateToDecimals((dataEnergieCumuleeTGBT.data.valeur) * 0.291) : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="a-payer">
                                    <h1>A payer :</h1>
                                    <div className="image-container">
                                        <img src={process.env.REACT_APP_BASE_URL + "/assets/images/prodPV/bouton-rouge.png"} alt="A payer" />
                                        <div className="overlay-text">{(dataEnergieCumulee != undefined && dataEnergieCumuleeTGBT != undefined) ? truncateToDecimals(((dataEnergieCumuleeTGBT.data.valeur) * 0.291) - ((dataEnergieCumulee.data.valeur) * 0.291)) : ""}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ProdPvRealTime;
