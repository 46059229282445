import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../components/PasswordInput';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import { useParams } from 'react-router-dom';


const ResetPassConfirm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/reset-password`, { token, password, confirmPassword });
            toast.success(response.data);
            setTimeout(() => {
                navigate('/login');
            }, 3000); //3 secs
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data);
            } else if (error.response && error.response.status === 404) {
                toast.error(error.response.data);
            } else {
                toast.error(error.response.data);
            }
        }
    };

    return (
        <div className="page-register">
            <div className="container-reset">
                <h1 className="heading-reset">Réinitialisation mot de passe </h1>
                <p className="text-reset">
                Créer un nouveau mot de passe
                </p>
                <form onSubmit={handleSubmit} className="form-reset-confirm">
                    <PasswordInput
                        label="Mot de passe"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <PasswordInput
                        label="Confirmer mot de passe"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button type="submit" className="button-reset">Réinitialiser</button>
                </form>
            </div>
            <ToastContainer
                className="toast-container"
                autoClose={2000}
                closeButton={false}
                theme={"colored"}
                icon={true}
            />
        </div>
    );
};

export default ResetPassConfirm;
