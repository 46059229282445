import { io } from 'socket.io-client';
import Highcharts from  "highcharts/highstock";
const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT;


var socket = io(ENDPOINT);


async function RealTimeCosphi(socket, todaycosphi1, todaycosphi2, todaycosphi3) {
    // Create the chart
    Highcharts.setOptions({
    time: {
        useUTC: false
    }
});
    Highcharts.stockChart('COSPHI', {
        chart: {
            zoomType: "x",
            events: {
                load: function () {
                    var p1 = this.series[0];
                    try {
                        socket.on('data', async function (data) { 
                            if (data.machine.id_machine === localStorage.getItem('machine_selected') ) {
                                var x = new Date(data.time.time).getTime() - 1 * 60 * 60 * 1000, // current time
                                    y = parseFloat(Math.round(((data.cosphi.cosphi1 + data.cosphi.cosphi1 + data.cosphi.cosphi1)/3) * 1000, 3)) / 1000;
                                p1.addPoint([x, y], true, true);
                            
                            }
                        });
                    } catch (error) {

                    }

                }
            }
        },

        time: {
            useUTC: false
        },

        xAxis: {
            type: 'datetime',
            range: 180000,
        },

        tooltip: {
            pointFormat: "{series.name} : {point.y}"
        },

        rangeSelector: {
            buttons: [{
                type: 'all',
                text: '24H'
            }],
            inputEnabled: false,
            selected: 2
        },

        title: {
            text: ''
        },

        exporting: {
            enabled: true
        },

        dataSorting: {
            enabled: true
        },

        series: [{
            type: "spline",
            name: 'Cosphi',
            data: (function () {
                var data = [],
                    time = (new Date()).getTime(),
                    i;

                    for (i = -todaycosphi1.length; i < 0; i += 1) {
                        data.push([
                            todaycosphi1[todaycosphi1.length + i][0],
                            Math.round(((todaycosphi1[todaycosphi1.length + i][1] + todaycosphi2[todaycosphi2.length + i][1] + todaycosphi3[todaycosphi3.length + i][1]) / 3) * 1000, 3) / 1000
                        ]);
                    }
                    return data;
            }()),
        },

        ]
    });
}


export default RealTimeCosphi
