import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import axios from "axios";
import jsPDF from "jspdf";
import { factureSteg } from "../components/factureStegImage";
import { useNavigate } from "react-router-dom";

function Facture() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const navigate = useNavigate();
  let defaultValue = "";
  const selectAllOption = { label: "Sélectionnez tout", value: "*" };
  const options = [];
  function setOptions() {
    const listMachines = JSON.parse(localStorage.getItem("machines"));
    Object.keys(listMachines).map((index) => {
      var details = {
        value: listMachines[index].machine_id,
        label: listMachines[index].machine_name,
      };
      options.push(details);
    });
  }

  setOptions();
  const [selected, setSelected] = useState({
    machines: [],
    annee_fin: "",
    mois_fin: "",
    jour_fin: "",
    annee_debut: "",
    mois_debut: "",
    jour_debut: "",
    timeDebut: "",
    timeFin: "",
  });
  const initialVisibleOptions =
    options.length === defaultValue?.length
      ? options
      : [selectAllOption, ...options];
  const [availableOptions, setAvailableOptions] = useState(
    initialVisibleOptions
  );
  const [selectedValues, setSelectedValues] = useState(defaultValue);
  const [selectedVal, setSelectedVal] = useState(selected.machines);

  const [selectedDate, setSelectedDate] = useState({
    fin: "",
    debut: "",
  });
  const [selectedTime, setSelectedTime] = useState({
    timeDebut: "",
    timeFin: "",
  });
  const [dataInvoice, setDataInvoice] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCard = () => {
    setShowCard(true);
    setIsLoading(false);
  };
  const handleChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find(
      (o) => o.value === selectAllOption.value
    );

    const newComponentState = selectAllIsSelected
      ? {
          selectedValues: options,
          availableOptions: [],
        }
      : {
          selectedValues: newSelectedOptions,
          availableOptions: initialVisibleOptions,
        };

    setSelectedValues(newComponentState.selectedValues);
    setAvailableOptions(newComponentState.availableOptions);
    // this.onChange(newComponentState.selectedValues);
  };

  function isLater(dateString1, dateString2) {
    return new Date(dateString1) > new Date(dateString2)
  }

  function isLaterTime(dateString1, dateString2) {
    return dateString1 > dateString2
  }

  const handleErrors = async () => {
    const date_deb = selected.annee_debut +"-" + selected.mois_debut + "-" + selected.jour_debut;
    
    const date_fin = selected.annee_fin + "-" + selected.mois_fin +"-"+ selected.jour_fin;

    if (Object.keys(selectedVal).length === 0) {
      toast.error("Veuillez sélectionner au moins une machine");
    } else if (selected.annee_debut === "") {
      toast.error("Veuillez sélectionner date de début");
    } else if (selected.annee_fin === "") {
      toast.error("Veuillez sélectionner date de fin");
    } 
    // else if (isLater(date_deb , date_fin)) {
    //   toast.error("La date de début doit être anterieur a la date fin");
    // }
    //  else if (isLaterTime(selected.timeDebut, selected.timeFin)) {
    //   toast.error("Le temps de début doit être anterieur au temps de fin");
    // } 
    else {
      if (selected.timeDebut == "") {
       
        var body = {
          year: selected.annee_debut,
          month: selected.mois_debut,
          day: selected.jour_debut,
          year1: selected.annee_fin,
          month1: selected.mois_fin,
          day1: selected.jour_fin,
          time1: "00",
          time2: "00",
          ids_machine: selectedVal,
        };
      } else {

        var body = {
          year: selected.annee_debut,
          month: selected.mois_debut,
          day: selected.jour_debut,
          year1: selected.annee_fin,
          month1: selected.mois_fin,
          day1: selected.jour_fin,
          time1: selected.timeDebut,
          time2: selected.timeFin,
          ids_machine: selectedVal,
        };
      }
      var time_debut = "";
      var time_fin = "";
      try {
        if(selected.timeDebut=="" || selected.mois_debut + '/' + selected.jour_debut + '/' + selected.annee_debut != selected.mois_fin + '/' + selected.jour_fin + '/' + selected.annee_fin){
        time_debut = "00:00";
      }else {
        time_debut = selected.timeDebut;
      }
      if(selected.timeFin=="" ||selected.mois_debut + '/' + selected.jour_debut + '/' + selected.annee_debut != selected.mois_fin + '/' + selected.jour_fin + '/' + selected.annee_fin){
        var time = new Date()
        var hour = time.getHours();
        var minutes = time.getMinutes()-1;
        if (hour < 10) {
          hour = "0" + hour
        }
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        var new_time = hour + ':' + minutes
        time_fin  = new_time;
      }
      else {
        time_fin = selected.timeFin;
      }
      const response = await axios.get(process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/historique/checkDates/'
        + selected.mois_debut + '/'
        + selected.jour_debut + '/'
        + selected.annee_debut + '/'
        + time_debut + '/'
        + selected.mois_fin + '/'
        + selected.jour_fin + '/'
        + selected.annee_fin + '/'
        + time_fin,
        {
          headers: {
            'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
          }
        }
      );
      if (response.status === 200) {
      try {
        setIsLoading(true);

        await axios.post(
        
            process.env.REACT_APP_BACKEND_IP +
            ":" +
            process.env.REACT_APP_BACKEND_PORT +
            "/facture/dataFacture/",
          body,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        )
          .then(res => {
            if (res.data.status === "Empty"){
              setIsLoading(false);
              toast.error("Vous devez sélectionnez votre type facture !")
              setTimeout(() => {
                navigate("/paramsFact")
              }, 3000);
            }
            if (res.data.status === "full"){
              setDataInvoice(res.data.facture);
              handleCard();
              setIsLoading(false);
            }
          })
      } catch (error) {
        console.log(error);
      }
    }
     
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const response = error.response.data;
        // Handle validation errors
        if (response.date1IsBeforedate2 === false) {
          toast.error("Le temps de début doit être antérieur au temps de fin");
        } 
        else if (response.date1IsBeforeNow === false) {
          toast.error("Le temps de début doit être antérieur au temps actuelle");
        } else if (response.date2IsBeforeNow === false) {
          toast.error("Le temps de fin doit être antérieur au temps actuelle");
        } else {
          toast.error("Invalid dates provided.");
        }
      } else {
        console.log(error);
        toast.error("Une erreur s'est produite lors de la vérification des dates");
      }
    }
    
    }
  };

  const handleDateChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
    if (type == "fin") {
      setSelected((prevState) => ({
        ...prevState,
        annee_fin: year,
        mois_fin: month,
        jour_fin: day,
      }));
    } else {
      setSelected((prevState) => ({
        ...prevState,
        annee_debut: year,
        mois_debut: month,
        jour_debut: day,
      }));
    }
  };

  const handleTimeChange = (date, type) => {
    var time = new Date(date);
    var hour = time.getHours();
    var minutes = time.getMinutes();
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var new_time = hour + ":" + minutes;
    setSelectedTime((prevState) => ({ ...prevState, [type]: time }));
    setSelected((prevState) => ({ ...prevState, [type]: new_time }));
  };

  const tableFacture = (data) => {
    if (data.parametre.type == "parpointe") {
      return (
        <tbody>
          <tr>
            <td>Jour</td>
            <td>{Math.round(data.sum_energie_jour * 100) / 100} kWh</td>
            <td>{Math.round(data.jour * 100) / 100} DT</td>
          </tr>
          <tr>
            <td>Pointe matin [été]</td>
            <td>{Math.round(data.sum_energie_matin_ete * 100) / 100} kWh</td>
            <td>{Math.round(data.matin_ete * 100) / 100} DT</td>
          </tr>
          <tr>
            <td>Pointe soir</td>
            <td>{Math.round(data.sum_energie_soir * 100) / 100} kWh</td>
            <td>{Math.round(data.soir * 100) / 100} DT</td>
          </tr>
          <tr>
            <td>Nuit</td>
            <td>{Math.round(data.sum_energie_nuit * 100) / 100} kWh</td>
            <td>{Math.round(data.nuit * 100) / 100} DT</td>
          </tr>
          <tr>
            <td></td>
            <td>Total = {Math.round(data.sum_energie * 100) / 100} kWh</td>
            <td>
              Total ={" "}
              {Math.round(
                (data.jour + data.soir + data.nuit + data.matin_ete) * 100
              ) / 100}{" "}
              DT
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td>Jour</td>
            <td>{Math.round(dataInvoice.sum_energie * 100) / 100} kWh</td>
            <td>{Math.round(dataInvoice.total_generale * 100) / 100} DT</td>
          </tr>
          <tr>
            <td></td>
            <td>
              Total = {Math.round(dataInvoice.sum_energie * 100) / 100} kWh
            </td>
            <td>
              Total = {Math.round(dataInvoice.total_generale * 100) / 100} DT
            </td>
          </tr>
        </tbody>
      );
    }
  };

  useEffect(() => {
    setSelectedVal(
      Object.keys(selectedValues).map(
        (element) => selectedValues[element].value
      )
    );
  }, [selectedValues, dataInvoice]);
  const FactureTab = () => {
    return (
      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card custom-card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table border text-nowrap text-md-nowrap table-striped">
                  <thead>
                    <tr>
                      <th>Période</th>
                      <th>Nombre de KWh</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  {Object.keys(dataInvoice).length > 0 ? (
                    tableFacture(dataInvoice)
                  ) : (
                    <></>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const dateDebut =
    selected.jour_debut +
    " - " +
    selected.mois_debut +
    " - " +
    selected.annee_debut;
  const dateFin =
    selected.jour_fin + " - " + selected.mois_fin + " - " + selected.annee_fin;
  const generatePDF = (data) => {
    var doc = new jsPDF();
    var imgData = factureSteg;
    doc.addImage(imgData, "JPEG", 0, 0, 210, 297);
    doc.setFontSize(10);
    if (data.parametre.type == "parpointe") {
      doc.text(data.parametre.district, 170, 30);
      doc.text(data.parametre.tel, 170, 43);
      doc.text(data.parametre.fax, 105, 43);
      doc.text(data.parametre.consommateur, 120, 55);
      doc.text(data.parametre.adresse, 110, 61);
      doc.text(data.parametre.payeur, 25, 54);
      doc.text(data.parametre.adresse, 26, 59);
      var fact = getRandomInt(99999999).toString();

      doc.text(fact, 95, 30);
      doc.text(dateDebut + " -> " + dateFin, 78, 34);
      doc.text(data.parametre.ur, 185, 82);
      doc.text(data.parametre.reference, 165, 82);
      doc.text(data.parametre.codePayeur, 143, 82);
      doc.text(data.parametre.RIB, 90, 82);

      doc.text(
        (data.parametre.PuissanceSouscritePointeJour * 1000).toString(),
        171,
        155
      );
      doc.text(
        (data.parametre.PuissanceSouscritePointeEte * 1000).toString(),
        151,
        155
      );
      doc.text(
        (data.parametre.PuissanceSouscritePointeSoir * 1000).toString(),
        131,
        155
      );
      doc.text(
        (data.parametre.PuissanceSouscritePointeHiver * 1000).toString(),
        111,
        155
      );
      doc.text(
        (data.parametre.PuissanceSouscritePointeReduite * 1000).toString(),
        91,
        155
      );

      doc.text((data.parametre.MaxAppPointeJour * 1000).toString(), 171, 162);
      doc.text((data.parametre.MaxAppPointeEte * 1000).toString(), 151, 162);
      doc.text((data.parametre.MaxAppPointeSoir * 1000).toString(), 131, 162);
      doc.text((data.parametre.MaxAppPointeHiver * 1000).toString(), 111, 162);
      doc.text((data.parametre.MaxAppReduite * 1000).toString(), 91, 162);

      var SommeDT =
        Math.round((data.jour + data.soir + data.nuit + data.matin_ete) * 100) /
        100;

      doc.text(
        (Math.round(data.sum_energie_jour * 100) / 100).toString(),
        15,
        119
      );
      doc.text(
        (Math.round(data.sum_energie_matin_ete * 100) / 100).toString(),
        15,
        122
      );
      doc.text(
        (Math.round(data.sum_energie_soir * 100) / 100).toString(),
        15,
        125
      );
      doc.text(
        (Math.round(data.sum_energie_nuit * 100) / 100).toString(),
        15,
        128
      );
      doc.text((Math.round(data.sum_energie * 100) / 100).toString(), 15, 133);

      doc.text((Math.round(data.jour * 100) / 100).toString(), 18, 156);
      doc.text((Math.round(data.matin_ete * 100) / 100).toString(), 18, 159);
      doc.text((Math.round(data.soir * 100) / 100).toString(), 18, 162);
      doc.text((Math.round(data.nuit * 100) / 100).toString(), 18, 165);
      doc.text(SommeDT.toString(), 18, 170);

      doc.text((data.parametre.tarif_jour * 1000).toString(), 38, 156);
      doc.text((data.parametre.tarif_jour_ete * 1000).toString(), 38, 159);
      doc.text((data.parametre.tarif_soir * 1000).toString(), 38, 162);
      doc.text((data.parametre.tarif_soir * 1000).toString(), 38, 165);

      doc.text(
        (Math.round(data.sum_energie_jour * 100) / 100).toString(),
        55,
        156
      );
      doc.text(
        (Math.round(data.sum_energie_matin_ete * 100) / 100).toString(),
        55,
        159
      );
      doc.text(
        (Math.round(data.sum_energie_soir * 100) / 100).toString(),
        55,
        162
      );
      doc.text(
        (Math.round(data.sum_energie_nuit * 100) / 100).toString(),
        55,
        165
      );
      doc.text((Math.round(data.sum_energie * 100) / 100).toString(), 55, 170);

      doc.text("--", 18, 176);
      doc.text("--", 18, 182);
      doc.text(SommeDT.toString(), 18, 188);

      doc.text("--", 18, 193);
      doc.text("--", 18, 196);
      doc.text("--", 18, 199);
      doc.text("--", 18, 202);
      doc.text("--", 18, 205);
      doc.text("--", 18, 208);

      doc.text(SommeDT.toString(), 18, 215);

      var tvaConsommation =
        Math.round(((data.sum_energie * 19) / 100) * 100) / 100;
      var tvaRedevance =
        Math.round(((data.sum_energie * 1.5) / 100) * 100) / 100;

      doc.text(tvaConsommation.toString(), 18, 220);
      doc.text(tvaRedevance.toString(), 18, 223);
      doc.text("3.50", 18, 226);

      var srttmun = Math.round(data.sum_energie * 0.005 * 100) / 100;

      doc.text(srttmun.toString(), 18, 229);

      doc.text(
        (tvaConsommation + tvaRedevance + 3.5 + srttmun).toString(),
        18,
        236
      );

      doc.text((Math.round((SommeDT + tvaConsommation + tvaRedevance + 3.5 + srttmun)*100)/100).toString(),
        18,
        248
      );

      doc.text((Math.round((SommeDT + tvaConsommation + tvaRedevance + 3.5 + srttmun)*100)/100).toString(),
        175,
        277
      );

      doc.text(fact, 130, 277);
      doc.text(data.parametre.codePayeur, 110, 277);
      doc.text(data.parametre.reference, 90, 277);
      doc.text(data.parametre.ur, 80, 277);
      doc.text(data.parametre.district, 34, 283);
    } else {
      doc.text(data.parametre.district, 170, 30);
      doc.text(data.parametre.tel, 170, 43);
      doc.text(data.parametre.fax, 105, 43);
      doc.text(data.parametre.consommateur, 120, 55);
      doc.text(data.parametre.adresse, 110, 61);
      doc.text(data.parametre.payeur, 25, 54);
      doc.text(data.parametre.adresse, 26, 59);
      var facture = getRandomInt(99999999).toString();

      doc.text(facture, 95, 30);
      doc.text(dateDebut + " -> " + dateFin, 78, 34);
      doc.text(data.parametre.ur, 185, 82);
      doc.text(data.parametre.reference, 165, 82);
      doc.text(data.parametre.codePayeur, 143, 82);
      doc.text(data.parametre.RIB, 90, 82);

      doc.text ((data.parametre.puissance_souscrite * 1000).toString(), 171, 155) // puissance souscrite jour (constante)
      doc.text("", 151, 155)
      doc.text("", 131, 155)
      doc.text("", 111, 155)
      doc.text((data.parametre.puissance_souscrite * 1000).toString(), 91, 155) // puissance souscrite reduite (constante)
      doc.text("", 91, 162)
      if (data.parametre.Depassement_puissance_reduite > 0 ) {
        doc.text(data.parametre.Depassement_puissance_reduite.toString(), 91, 167) // Depassement puissance reduite 
      }
      else {
        doc.text("", 91, 167) // Depassement puissance reduite 
      }
      
      doc.text("", 111, 162)
      doc.text((data.parametre.max_appelle * 1000).toString(), 171, 162); // puissance maximale appellé (constante)
      doc.text((data.parametre.installe * 1000).toString(), 171, 173); // puissabce installé (constante)
      if (data.moy_cosphi == 0){
        doc.text("", 171, 181) // cosphi 
      } 
      else {
        doc.text((data.moy_cosphi).toString(), 171, 181) // cosphi 
      }
      if (data.contribution_gmg == 0){
        doc.text("", 85, 181) // contribution gmg
      }
      else {
        doc.text("CONTRIBUTION GMG: " + data.contribution_gmg, 85, 181) // contribution gmg
      }
      if (data.coe_k == 0){
        doc.text("", 171, 188) // coe K 
      }
      else {
        doc.text((data.coe_k).toString(), 171, 188) // coe K 
      }
     

      doc.text((data.sum_energie).toString(), 15, 119)
      doc.text("".toString(), 15, 122)
      doc.text("".toString(), 15, 125)
      doc.text("".toString(), 15, 128)
      doc.text((data.sum_energie).toString(), 15, 133)

      doc.text((data.total).toString(), 18, 156)
      doc.text("".toString(), 18, 159)
      doc.text("".toString(), 18, 162)
      doc.text("".toString(), 18, 165)
      doc.text((data.total).toString(), 18, 170)

      doc.text((data.parametre.tarif * 1000).toString(), 38, 156)
      doc.text('', 38, 159)
      doc.text('', 38, 162)
      doc.text('', 38, 165)

      doc.text(data.sum_energie.toString(), 51, 156)
      doc.text("", 51, 159)
      doc.text("", 51, 162)
      doc.text("", 51, 165)
      doc.text(data.sum_energie.toString(), 51, 170)

      if (data.bonification == 0){
        doc.text("", 18, 176)
      }
      else {
        doc.text((data.bonification).toString(), 18, 176)
      }
      if (data.penality == 0){
        doc.text("", 18, 182)
      }
      else {
        doc.text((data.penality).toString(), 18, 182)
      }
      if (data.total1 == 0){
        doc.text("", 18, 188)
      }
      else {
        doc.text((data.total1).toString(), 18, 188)
      }
      
      if (data.prime_de_puissance == 0){
        doc.text("", 18, 193)
      }
      else {
        doc.text((data.prime_de_puissance).toString(), 18, 193)
      }
     
      if (data.Depassement_puissance_price > 0 ) {
        doc.text((data.Depassement_puissance_price).toString(), 18, 196)
      }
        else {
          doc.text("", 18, 196)
        }
        doc.text('', 18, 199)
        doc.text('', 18, 202)
        doc.text('', 18, 205)
        doc.text("", 18, 208)
        if (data.total2 == 0 ){
          doc.text("", 18, 215)
        } 
        else {
          doc.text((data.total2).toString(), 18, 215)
        }
       
        if (data.TVA_consommation > 0){
          doc.text(data.TVA_consommation.toString(), 18, 220)
        } else  {
          doc.text("", 18, 220)
        }
        if (data.TVA_redevance > 0){
          doc.text((data.TVA_redevance).toString(), 18, 223)
        } else  {
          doc.text("", 18, 223)
        }
      
        if (data.contributionRTT == 0 ){
          doc.text("", 18, 226)
        } 
        else {
          doc.text((data.contributionRTT).toString(), 18, 226)
        }
     
      if (data.surtaxe_municipale == 0 ){
        doc.text("", 18, 229)
      } 
      else {
        doc.text((data.surtaxe_municipale).toString(), 18, 229)
      }
     

      if (data.total3 == 0 ){
        doc.text("", 18, 236)
      } 
      else {
        doc.text((data.total3).toString(), 18, 236)
      }
     

      doc.text((data.total_generale).toString() , 18, 248)

      doc.text((data.total_generale).toString() , 175, 277)
      // doc.text(
      //   (data.parametre.puissance_souscrite * 1000).toString(),
      //   171,
      //   155
      // );
      // doc.text("", 151, 155);
      // doc.text("", 131, 155);
      // doc.text("", 111, 155);
      // doc.text("300", 91, 155);
      // doc.text("", 91, 162);
      // doc.text("", 111, 162);
      // doc.text((data.parametre.max_appelle * 1000).toString(), 171, 162);
      // doc.text((data.parametre.installe * 1000).toString(), 171, 173);
      // doc.text(data.moy_cosphi.toString(), 171, 181);
      // doc.text(
      //   "CONTRIBUTION GMG: " + data.contribution_gmc.toString(),
      //   85,
      //   181
      // );
      // doc.text(data.coe_k.toString(), 171, 188);
      // var zero = 0;
      // doc.text(data.sum_energie.toString(), 15, 119);
      // doc.text(zero.toString(), 15, 122);
      // doc.text(zero.toString(), 15, 125);
      // doc.text(zero.toString(), 15, 128);
      // doc.text(data.sum_energie.toString(), 15, 133);

      // doc.text(data.total.toString(), 18, 156);
      // doc.text(zero.toString(), 18, 159);
      // doc.text(zero.toString(), 18, 162);
      // doc.text(zero.toString(), 18, 165);
      // doc.text(data.total.toString(), 18, 170);

      // doc.text((data.parametre.tarif * 1000).toString(), 38, 156);
      // doc.text("", 38, 159);
      // doc.text("", 38, 162);
      // doc.text("", 38, 165);

      // doc.text(data.sum_energie.toString(), 62, 156);
      // doc.text(zero.toString(), 62, 159);
      // doc.text(zero.toString(), 62, 162);
      // doc.text(zero.toString(), 62, 165);
      // doc.text(data.sum_energie.toString(), 62, 170);

      // doc.text(data.bonification.toString(), 18, 176);
      // doc.text("--", 18, 182);
      // doc.text((Math.round(data.total1 * 100) / 100).toString(), 18, 188);

      // doc.text(
      //   (Math.round(data.prime_de_puissance * 100) / 100).toString(),
      //   18,
      //   193
      // );
      // doc.text("--", 18, 196);
      // doc.text("--", 18, 199);
      // doc.text("--", 18, 202);
      // doc.text("--", 18, 205);
      // doc.text("--", 18, 208);

      // doc.text(
      //   (Math.round(data.prime_de_puissance * 100) / 100).toString(),
      //   18,
      //   215
      // );

      // doc.text("--", 18, 220);
      // doc.text("--", 18, 223);
      // doc.text("3.50", 18, 226);

      // doc.text(data.surtaxe_municipale.toString(), 18, 229);

      // doc.text((Math.round(data.total3 * 100) / 100).toString(), 18, 236);

      // doc.text((Math.round(data.total_generale * 100) / 100).toString(), 18, 248);

      // doc.text((Math.round(data.total_generale * 100) / 100).toString(), 175, 277);
   
      doc.text(facture, 130, 277);
      doc.text(data.parametre.codePayeur, 110, 277);
      doc.text(data.parametre.reference, 90, 277);
      doc.text(data.parametre.ur, 80, 277);
      doc.text(data.parametre.district, 34, 283);
    }

    doc.save("Facture Estimée.pdf");
  };
  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="facture" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Estimation facture STEG</h1>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Sélectionnez vos paramètres </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">
                        Machines
                      </label>
                      <div className="form-group col-md-10 col-lg-10 col-xl-11">
                        <SelectBox
                          options={availableOptions}
                          value={selectedValues}
                          defaultValue={selectedValues}
                          onChange={handleChange}
                          placeholder={"Selectionnez machine(s)..."}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <label className="form-label col-md-3 col-lg-3 col-xl-1">
                        Date
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <DatePicker
                          id={"dateDebut"}
                          value={selectedDate.debut}
                          onChange={(e) => handleDateChange(e, "debut")}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <DatePicker
                          id={"dateFin"}
                          value={selectedDate.fin}
                          onChange={(e) => handleDateChange(e, "fin")}
                        />
                      </div>

                      <label className="form-label col-md-3 col-lg-3 col-xl-1">
                        Temps
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <TimePicker
                          id={"timeDebut"}
                          value={selectedTime.timeDebut}
                          onChange={(e) => handleTimeChange(e, "timeDebut")}
                          disabled={
                            selected.jour_debut === selected.jour_fin &&
                            selected.mois_debut === selected.mois_fin &&
                            selected.annee_debut === selected.annee_fin
                              ? false
                              : true
                          }
                        />
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <TimePicker
                          id={"timeFin"}
                          value={selectedTime.timeFin}
                          onChange={(e) => handleTimeChange(e, "timeFin")}
                          disabled={
                            selected.jour_debut === selected.jour_fin &&
                            selected.mois_debut === selected.mois_fin &&
                            selected.annee_debut === selected.annee_fin
                              ? false
                              : true
                          }
                        />
                      </div>

                      <div className="col-md-12 col-lg-12 col-xl-2">
                        <button
                          className="btn btn-outline-primary btn-block"
                          type="submit"
                          onClick={(e) => handleErrors()}
                        >
                          <i className="fa fa-calculator me-2"></i>
                          Calculer
                        </button>
                        <button
                          className="btn btn-outline-danger btn-block"
                          type="submit"
                          onClick={() => Object.keys(dataInvoice).length > 0 ? generatePDF(dataInvoice) : toast.warning("Il faut d'abord CALCULER votre facture !")}
                        >
                          <i className="fa fa-file-pdf-o me-2"></i>Génèrer facture STEG
                        </button>
                        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {   isLoading ? ( 
                          <BouncingDotsLoader/>
                  ) : showCard ? <FactureTab /> : <></>}
              </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Facture;
