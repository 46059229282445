import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddUser() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        nom: "",
        nom_utilisateur: "",
        email: "",
        password: "",
        role: "",
    });
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    const handleAdd = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/users`,
                userData ,
                {
                    headers: {
                      'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                    }
                  }
            );

            if (response.status === 201) {
                toast.success("Utilisateur ajouté avec succès");
                setUserData({
                    nom: "",
                    nom_utilisateur: "",
                    email: "",
                    password: "",
                    role: "",
                });
                navigate("/gestionUtilisateurs")
            }
        } catch (error) {
            console.error("Error creating user:", error);
            toast.error("Erreur lors de l'ajout de l'utilisateur");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="gestionUtilisateurs" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Ajouter nouveau utilisateur</h1>
                                    </div>
                                </div>

                                <div className="card">
                                    {/* <div className="card-title">

                                    </div> */}
                                    <div className="card-body">
                                    <label className="form-label col-12"> Nom Société </label>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={userData.nom}
                                                name="nom"
                                                placeholder="Saisir nom et prénom..."
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="form-label col-12"> Nom et prénom </label>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={userData.nom_utilisateur}
                                                name="nom_utilisateur"
                                                placeholder="Saisir nom et prénom..."
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="form-label col-12"> Email </label>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={userData.email}
                                                name="email"
                                                placeholder="Saisir Email..."
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="form-label col-12"> Mot de passe </label>
                                        <div className="form-group col-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={userData.password}
                                                name="password"
                                                placeholder="Saisir mot de passe..."
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <label className="form-label col-12"> Rôle </label>
                                        <div className="row col-12">
                                            <div className="col-1">
                                                <input
                                                    type="radio"
                                                    value="0"
                                                    name="role"
                                                    checked={userData.role === "0"}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-label-checkbox">Admin</label>
                                            </div>
                                            <div className="col-11">
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    name="role"
                                                    checked={userData.role === "1"}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-label-checkbox">Utilisateur</label>
                                            </div>

                                            <div className="col-12 d-flex flex-column justify-content-between align-items-end" style={{ height: '100%' }}>

                                                <div style={{ flex: 1 }}></div>
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={() => handleAdd()}
                                                >
                                                    Ajouter Utilisateur
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <ToastContainer
                                    className="toast-container"
                                    autoClose={2000}
                                    closeButton={false}
                                    theme={"colored"}
                                    icon={true}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default AddUser;
