import React from "react";
import Select from "react-select";

function SelectOne(props) {
  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused ? "#5E6EF1" : null,
      color: state.isFocused ? "white" : "black",

    })
  };
  return (
    
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#5E6EF1',
          primary: '#5E6EF1',
        },
        backgroundColor :'#5E6EF1',
      })}
      styles={styles}
      options={props.options}
      placeholder={props.placeholder}
      onChange = {props.onChange}
      name = {props.name}
      menuPlacement={props.menuPlacement}
      menuPosition={props.menuPosition}
      autosize={props.autosize}
    />
     
  );
}

export default SelectOne