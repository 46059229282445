import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>  
      {/* FOOTER */}
      <footer className="footer">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-12 col-sm-12 text-center">
              Copyright © {currentYear} <a href="https://electrosoft.com.tn/enerco/" target="_blank">ENERCO</a> Designed by{" "}
              <a href="https://electrosoft.com.tn/" target="_blank">ELECTROSOFT</a> All rights reserved
            </div>
          </div>
        </div>
      </footer>
      {/* FOOTER CLOSED */}
    </>
  );
}

export default Footer;
