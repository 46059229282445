import React from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "rgba(73, 80, 87, 0.75)",
      "background-color": "#fff",
      border: "1px solid #eaedf1",
      "border-radius": "7px",
      "font-size": "0.875rem",
      "font-weight": "400",
      "padding": "2px 10px 1px",
      "height": "39px",
    }
  }
});

function DatePickerArchive(props) {
  const classes = useStyles();
  const today = new Date();
  const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 0);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={props.id}
        error={false}
        helperText={null}
        clearable
        className={classes.root}
        format="dd/MM/yyyy"
        value={props.value}
        onChange={props.onChange}
        maxDate={lastDayOfPreviousMonth}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerArchive;
