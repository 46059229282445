import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NumericInput from 'react-numeric-input';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditMachine() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const params = useParams()
  const id = params.id
  const navigate = useNavigate();
  const [errore, setErrore] = useState([]);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({
    name_machine: '',
    categorie: '',
    tensionMax: '',
    tensionMin: '',
    courantMax: '',
    courantMin: '',
    telResponsable: '',
    testImage: '',
    id: id
  });

  const {
    name_machine,
    categorie,
    tensionMax,
    tensionMin,
    courantMax,
    courantMin,
    telResponsable,
    testImage,
  } = data

  function formatTension(num) {
    return num + 'V';
  }
  function formatCourant(num) {
    return num + 'A';
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    if (errore.includes(name)) {
      setErrore(errore.filter((e) => e !== name))
    }
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleTelChange = (event) => {
    const { name, value } = event.target
    if (errore.includes(name)) {
      setErrore(errore.filter((e) => e !== name))
    }

    setData((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type === "image/jpeg" || file.type === "image/png") {
      setData((prevState) => {
        return {
          ...prevState,
          testImage: file,
        }
      })
    } else {
      toast.warning("Seulement les images de types JPEG ou PNG sont autorisées");
    }
  }

  const handleEditSubmit = async () => {
    const formData = new FormData()
    let lenghtTel = telResponsable && telResponsable.toString().length;
    const errorList = [];

    if (telResponsable !== 0) {
      if (lenghtTel !== 11 || telResponsable.toString().charAt(0) !== "2" || telResponsable.toString().charAt(1) !== "1" || telResponsable.toString().charAt(2) !== "6") {
        toast.error("Veuillez respecter le format de saisie de N° tel indiqué si dessous")
        errorList.push("telResponsable")
        setErrore(errorList)
      }
    } else {
      setErrore([])
    }

    if (tensionMax !== 0) {
      if (tensionMax < 240) {
        toast.warning("Veuillez saisir une tension Maximale supérieure à 240V")
        errorList.push("tensionMax")
        setErrore(errorList)
      }
    } else {
      setErrore([])
    }

    if (tensionMin !== 0) {
      if (tensionMin > 200) {
        toast.warning("Veuillez saisir une tension Minimale entre 0 et 200V")
        errorList.push("tensionMin")
        setErrore(errorList)
      }
    } else {
      setErrore([])
    }

    formData.append('nom', name_machine)
    formData.append('id_machine', data.id)
    formData.append('categorie', categorie)
    formData.append('tensionMinimal', tensionMin ? tensionMin : 0)
    formData.append('tensionMaximal', tensionMax ? tensionMax : "")
    formData.append('courantMinimal', courantMin ? courantMin : 0)
    formData.append('courantMaximal', courantMax ? courantMax : "")
    formData.append('numero', telResponsable ? telResponsable : "")
    formData.append('testImage', testImage)

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/machines/saveDataMachine/'
        , formData, 
        {
          headers: {
            'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
          }
        },
        {
      }).then(res => {
        if (res.status === 200 && res.data.status !== "Image machine est obligatoire") {
          toast.success(res.data.status)
          setTimeout(() => {
            navigate("/gestionMachines")
          }, 3000);
        }

        if (res.status === 200 && res.data.status === "Image machine est obligatoire") {
          toast.error(res.data.status)
        }
      })

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function fetchparams() {
      let paramsMachine;
      let categoriesList;
      try {
        paramsMachine = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/machines/getDataparametreMachineById/'
          + id,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        )
        setData((prevState) => ({
          ...prevState,
          name_machine: paramsMachine.data.nom,
          categorie: paramsMachine.data.categorie._id,
          tensionMax: paramsMachine.data.tensionMaximal,
          tensionMin: paramsMachine.data.tensionMinimal,
          courantMax: paramsMachine.data.courantMaximal,
          courantMin: paramsMachine.data.courantMinimal,
          telResponsable: paramsMachine.data.numero
        }))
      } catch (error) {
        console.log(error)
      }
    }

    async function fetchCategories() {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/categories/all/',
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        )
        setCategories(response.data.categories)
      } catch (error) {
        console.log(error)
      }
    }

    fetchparams();
    fetchCategories();
  }, [])

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionMachines" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier paramètres </h1>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">{"Machine : " + name_machine}</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Catégorie</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <select
                              className="form-control"
                              name="categorie"
                              value={categorie}
                              onChange={handleChange}
                              
                            >
                              <option value="" selected="selected" hidden="hidden">{categorie}</option>
                              {categories.map((cat) => (
                                <option key={cat._id} value={cat._id}>
                                  {cat.nom}
                                </option>
                              ))}
                            </select>
                          </div>
                          </div>
                          <div className="row">
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Tension Maximale</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <NumericInput
                              className={errore.includes("tensionMax") ? "form-control input-error" : "form-control"}
                              value={tensionMax ? tensionMax : ""}
                              placeholder="Saisir tension Max"
                              name="tensionMax"
                              min={0}
                              step={0.001}
                              maxLength={8}
                              format={formatTension}
                              onChange={tensionMax => handleChange({ target: { value: tensionMax, name: 'tensionMax' } })}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Tension Minimale</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <NumericInput
                              className={errore.includes("tensionMin") ? "form-control input-error" : "form-control"}
                              value={tensionMin ? tensionMin : ""}
                              placeholder="Saisir tension Min"
                              name="tensionMin"
                              min={0}
                              step={0.001}
                              maxLength={8}
                              format={formatTension}
                              onChange={tensionMin => handleChange({ target: { value: tensionMin, name: 'tensionMin' } })}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Courant Maximale</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <NumericInput
                              className="form-control"
                              value={courantMax ? courantMax : ""}
                              placeholder="Saisir courant Max"
                              name="courantMax"
                              min={0}
                              step={0.001}
                              format={formatCourant}
                              onChange={courantMax => handleChange({ target: { value: courantMax, name: 'courantMax' } })}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Courant Minimale</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <NumericInput
                              className="form-control"
                              value={courantMin ? courantMin : ""}
                              placeholder="Saisir courant Min"
                              name="courantMin"
                              min={0}
                              step={0.001}
                              format={formatCourant}
                              onChange={courantMin => handleChange({ target: { value: courantMin, name: 'courantMin' } })}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <label className="form-label col-md-4 col-lg-4 col-xl-3"> N° Télèphone </label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <input
                              type="number"
                              className={errore.includes("telResponsable") ? "form-control input-error" : "form-control"}
                              value={telResponsable ? telResponsable : ""}
                              onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                              placeholder="216 xx xxx xxx"
                              name="telResponsable"
                              onChange={handleTelChange}
                            />
                            <span className="span-error"> Veuillez saisir votre N° sous cette format 216 xx xxx xxx</span>
                          </div>
                          <label className="form-label col-md-4 col-lg-4 col-xl-3"> Image </label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
                          </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                          <div className="'col-md-12 col-lg-12 col-xl-2">
                            <button className="btn btn-outline-warning btn-block" type="submit" onClick={handleEditSubmit}>
                              Modifier
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default EditMachine;
