import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ResponsiveDataTable from '../components/ResponsiveDataTable';
import CardsTpsReel from '../components/CardsTpsReel';
import Sidebar from '../components/Sidebar';
import { io } from 'socket.io-client';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SelectOne from '../components/SelectOne';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RealTimePower from '../components/RealTimePower';
import RealTimeCurrent from '../components/RealTimeCurrent';
import RealTimeCosphi from '../components/RealTimeCosphi';
import ErrorBoundary from '../components/ErrorBoundary';

const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT;
const DEFAULT_MACHINE_ID = process.env.REACT_APP_DEFAULT_TGBT_ID;
const DEFAULT_MACHINE_NAME = process.env.REACT_APP_DEFAULT_TGBT_NAME;

function SuiviTempsReel() {
  const [selectedMachine, setSelected] = useState(DEFAULT_MACHINE_ID);
  const [selectedMachineName, setSelectedMachineName] = useState(DEFAULT_MACHINE_NAME);
  const [listMachine, setListMachine] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [realTimePower1, setRealTimePower1] = useState([]);
  const [realTimePower2, setRealTimePower2] = useState([]);
  const [realTimePower3, setRealTimePower3] = useState([]);
  const [realTimeCurrent1, setRealTimeCurrent1] = useState([]);
  const [realTimeCurrent2, setRealTimeCurrent2] = useState([]);
  const [realTimeCurrent3, setRealTimeCurrent3] = useState([]);
  const [realTimeCosphi1, setRealTimeCosphi1] = useState([]);
  const [realTimeCosphi2, setRealTimeCosphi2] = useState([]);
  const [realTimeCosphi3, setRealTimeCosphi3] = useState([]);
  const navigate = useNavigate();

  const handleCard = () => {
    setShowCard(true);
  };

  useEffect(() => {
    const verifyUser = async () => {
      if (localStorage.getItem('jwt') === null) {
        navigate("/login");
      } else {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}`,
            { token: localStorage.getItem('jwt') },
            {
              withCredentials: true,
            }
          );
          if (!data.status) {
            localStorage.removeItem('jwt');
            navigate('/login');
          }
        } catch (error) {
          console.log('Network Error:', error.message);
        }
      }
    };

    verifyUser();
    setListMachine(JSON.parse(localStorage.getItem('machines')));
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem('machine_selected') !== null && selectedMachine === DEFAULT_MACHINE_ID) {
      localStorage.removeItem('machine_selected');
    }
  }, [selectedMachine]);

  useEffect(() => {
    const defaultMachine = listMachine.find(machine => machine.machine_id === DEFAULT_MACHINE_ID);
    if (defaultMachine) {
      const event = { value: defaultMachine.machine_id, label: defaultMachine.machine_name };
      handleChange(event); 
    }
  }, [listMachine]);

  const handleChange = async (event) => {
    setShowCard(false);
    setRealTimePower1(null);
    setRealTimePower2(null);
    setRealTimePower3(null);
    setRealTimeCurrent1(null);
    setRealTimeCurrent2(null);
    setRealTimeCurrent3(null);
    setRealTimeCosphi1(null);
    setRealTimeCosphi2(null);
    setRealTimeCosphi3(null);

    setSelected(event.value);
    setSelectedMachineName(event.label);
    localStorage.setItem('machine_selected', event.value);

    try {
      const [power1, power2, power3] = await axios.all([
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/power1/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/power2/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/power3/${event.value}`)
      ]);

      const [current1, current2, current3] = await axios.all([
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/current1/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/current2/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/current3/${event.value}`)
      ]);

      const [cosphi1, cosphi2, cosphi3] = await axios.all([
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/cosphi1/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/cosphi2/${event.value}`),
        axios.get(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/data/cosphi3/${event.value}`)
      ]);

      if (power1.data.status === "no data to display" ||
          power2.data.status === "no data to display" ||
          power3.data.status === "no data to display" ||
          current1.data.status === "no data to display" ||
          current2.data.status === "no data to display" ||
          current3.data.status === "no data to display" ||
          cosphi1.data.status === "no data to display" ||
          cosphi2.data.status === "no data to display" ||
          cosphi3.data.status === "no data to display") {
        toast.warning("Aucune donnée disponible pour cette machine !");
        setShowCard(false);
      } else {
        setRealTimePower1(power1);
        setRealTimePower2(power2);
        setRealTimePower3(power3);
        setRealTimeCurrent1(current1);
        setRealTimeCurrent2(current2);
        setRealTimeCurrent3(current3);
        setRealTimeCosphi1(cosphi1);
        setRealTimeCosphi2(cosphi2);
        setRealTimeCosphi3(cosphi3);
        handleCard();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Cards = () => {
    useEffect(() => {
      const socket = io(ENDPOINT);
      const todaypower1 = realTimePower1.data;
      const todaypower2 = realTimePower2.data;
      const todaypower3 = realTimePower3.data;
      const todaycurrent1 = realTimeCurrent1.data;
      const todaycurrent2 = realTimeCurrent2.data;
      const todaycurrent3 = realTimeCurrent3.data;
      const todaycosphi1 = realTimeCosphi1.data;
      const todaycosphi2 = realTimeCosphi2.data;
      const todaycosphi3 = realTimeCosphi3.data;

      RealTimePower(socket, todaypower1, todaypower2, todaypower3);
      RealTimeCurrent(socket, todaycurrent1, todaycurrent2, todaycurrent3);
      RealTimeCosphi(socket, todaycosphi1, todaycosphi2, todaycosphi3);

      return () => {
        socket.disconnect();
      };
    }, []);
    return (
      <>
        <CardsTpsReel />
        <div className="card">
          <div className="card-header border-bottom">
            <h3 className="card-title">Puissance Active - {selectedMachineName}</h3>
          </div>
          <div id="P" className="chart-container" />
        </div>
        <div className="card">
          <div className="card-header border-bottom">
            <h3 className="card-title">Courant - {selectedMachineName}</h3>
          </div>
          <div id="C" className="chart-container" />
        </div>
        <div className="card">
          <div className="card-header border-bottom">
            <h3 className="card-title">Facteur de Puissance Moyen - {selectedMachineName}</h3>
          </div>
          <div id="COSPHI" className="chart-container" />
        </div>
      </>
    );
  };

  return (
    <>
      <ErrorBoundary>
        <div className="page">
          <div className="page-main">
            <Header />
            <Sidebar activeItem="suiviTpsReel" />
            <div className="app-content main-content mt-20">
              <div className="side-app">
                <div className="main-container container-fluid">
                  <div className="page-header">
                    <div>
                      <h1 className="page-title">Suivi Temps Réel</h1>
                    </div>
                    <div className="ms-auto pageheader-btn w-180 mtop-10">
                      <div className="form-group">
                        <div style={{ width: '200px' }}>
                          <SelectOne
                            placeholder={"Sélectionnez machine ..."}
                            options={
                              listMachine.map(element => (
                                { value: element.machine_id, label: element.machine_name }
                              ))
                            }
                            onChange={handleChange}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            autosize={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {showCard ? <Cards /> : <></>}
                  <ResponsiveDataTable listMachine={listMachine} handleChange={handleChange} />
                  <ToastContainer
                    className="toast-container"
                    autoClose={4000}
                    closeButton={false}
                    theme={"colored"}
                    icon={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    </>
  );
}

export default SuiviTempsReel;
