import React, { useState, useEffect } from "react";
import LazyLoad from 'react-lazyload';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from 'react-modal';

function GestionCategoriesMachines() {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display: "block",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  };

  const [categoryData, setCategoryData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  const openModal = (category) => {
    setSelectedCategory(category);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCategory(null);
  };

  const Suppression = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const encodedCategory = encodeURIComponent(selectedCategory);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/categories/categorie/${encodedCategory}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setCategoryData(categoryData.filter(category => category.nom !== selectedCategory));
      closeModal();
    } catch (error) {
      console.error("There was an error deleting the category!", error);
      closeModal();
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem('jwt');
        const dataCatgeory = await axios.get(
          `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/categories/all`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        setCategoryData(dataCatgeory.data.categories);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const _arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function tableBody(data) {
    if (Object.keys(data).length > 0) {
      return (
        <table
          id="basic-datatable"
          className="table table-bordered table-vcenter text-nowrap mb-0 w-100"
        >
          <thead>
            <tr>
              <th className="border-bottom-0">Catégorie</th>
              <th className="border-bottom-0">Icon</th>
              <th className="border-bottom-0 w-14">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(key => {
              return (
                <tr key={data[key]['nom']}>
                  <td>{data[key]['nom']}</td>
                  <td>
                    {data?.[key]?.img?.data?.data ?
                      <img
                        src={`data:${data[key]['img'].contentType};base64,${_arrayBufferToBase64(data[key]['img'].data.data)}`}
                        alt=""
                        className="icon-img"
                      />
                      : <img alt="" />}
                  </td>
                  <td>
                    <Link to={"/editCategory/" + data[key].nom}>
                      <button className="btn btn-warning btn-spinners ms-2" type="submit">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>
                    <button className="btn btn-danger btn-spinners ms-2" type="submit" onClick={() => openModal(data[key].nom)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Logout Confirmation"
                      style={customStyles}
                    >
                      <h4>Confirmer Suppression</h4>
                      <p>Êtes-vous sûr de vouloir supprimer ?</p>
                      <button className="btn btn-outline-primary btn-block " onClick={Suppression}>Confirmer</button>
                      <button className='btn btn-outline-danger btn-block' onClick={closeModal}>Annuler</button>
                    </Modal>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }
  }

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionCategory" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Gestion des catégories des machines</h1>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3 className="card-title">Listes des catégories</h3>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link to={"/addCategory"}>
                            <button className="btn btn-outline-primary" type="submit">
                              Ajouter catégorie
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <LazyLoad once>
                            {Object.keys(categoryData).length > 0 ? tableBody(categoryData) : (<></>)}
                          </LazyLoad>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default GestionCategoriesMachines;
