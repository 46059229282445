import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import DatePicker from "../components/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import axios from "axios";
import jsPDF from "jspdf";
import { factureExcedent } from "../components/factureExcedentImage";
import { toWords } from "../components/toWords";



function EstimationFactureExcedentPVMT() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  function truncateToDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
  }
  const [infoSection1, setInfoSection1] = useState({
    district: "",
    tel: "",
    fax: "",
    rib: "",
    comptageCompteurPJour: "",
    comptageCompteurPetematin: "",
    comptageCompteurPNuit: "",
    comptageCompteurPSoir: "",
    prixUnitairePJour: "",
    prixUnitairePetematin: "",
    prixUnitairePNuit: "",
    prixUnitairePSoir: "",
    pourcentageTVA: "",
    droitTimbre: "",
    pourcentageRSTVA: ""
  })

  const handleInfoSection1Change = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setInfoSection1((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const {
    district,
    tel,
    fax,
    rib,
    comptageCompteurPJour,
    comptageCompteurPetematin,
    comptageCompteurPNuit,
    comptageCompteurPSoir,
    prixUnitairePJour,
    prixUnitairePetematin,
    prixUnitairePNuit,
    prixUnitairePSoir,
    pourcentageTVA,
    droitTimbre,
    pourcentageRSTVA,
    status
  } = infoSection1

  const handleInfoSection1Submit = async (e) => {
    e.preventDefault();
    let data_section1_toadd;

    data_section1_toadd =
    {
      district: district,
      tel: tel,
      fax: fax,
      rib: rib,
      comptageCompteurPJour: comptageCompteurPJour,
      comptageCompteurPetematin: comptageCompteurPetematin,
      comptageCompteurPNuit: comptageCompteurPNuit,
      comptageCompteurPSoir: comptageCompteurPSoir,
      prixUnitairePJour: prixUnitairePJour,
      prixUnitairePetematin: prixUnitairePetematin,
      prixUnitairePNuit: prixUnitairePNuit,
      prixUnitairePSoir: prixUnitairePSoir,
      pourcentageTVA: pourcentageTVA,
      droitTimbre: droitTimbre,
      pourcentageRSTVA: pourcentageRSTVA
    }

    if (district == "" || tel == "" || fax == "" || rib == "" || comptageCompteurPJour == ""
      || comptageCompteurPetematin == "" || comptageCompteurPNuit == "" || comptageCompteurPSoir == "" ||
      prixUnitairePJour == "" || prixUnitairePetematin == "" || prixUnitairePNuit == "" || prixUnitairePSoir == ""
      || pourcentageTVA == "" || droitTimbre == "" || pourcentageRSTVA == "") {
      toast.error("les informations de la section 1 sont obligatoires !")
    } else {
      try {
        await axios
          .post(process.env.REACT_APP_BACKEND_IP
            + ':' + process.env.REACT_APP_BACKEND_PORT
            + '/factureExcedentPvMt/saveParametreFactureExcedentMt/', data_section1_toadd,
            {
              headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
              }
            }
          )
          .then((response) => console.log("response data from axios post data", response.data))

        toast.success("Vos paramètres ont été ajoutés avec succès")
        setButtonText("Modifier");
      } catch (error) {
        console.log(error)
        toast.error("les informations de la section 1 sont obligatoires !")
      }
    }


  }
  async function fetchData() {
    let dataParamsSection1;
    try {
      dataParamsSection1 = await axios.get(process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/factureExcedentPvMt/getParamterFactureExcedentMt/',
        {
          headers: {
            'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
          }
        }
      )
    } catch (error) {
      console.log(error)
    }

    if (dataParamsSection1 && dataParamsSection1.data) {
      setInfoSection1({
        district: dataParamsSection1.data.district,
        tel: dataParamsSection1.data.tel,
        fax: dataParamsSection1.data.fax,
        rib: dataParamsSection1.data.rib,
        comptageCompteurPJour: dataParamsSection1.data.comptageCompteurPJour,
        comptageCompteurPetematin: dataParamsSection1.data.comptageCompteurPetematin,
        comptageCompteurPNuit: dataParamsSection1.data.comptageCompteurPNuit,
        comptageCompteurPSoir: dataParamsSection1.data.comptageCompteurPSoir,
        prixUnitairePJour: dataParamsSection1.data.prixUnitairePJour,
        prixUnitairePetematin: dataParamsSection1.data.prixUnitairePetematin,
        prixUnitairePNuit: dataParamsSection1.data.prixUnitairePNuit,
        prixUnitairePSoir: dataParamsSection1.data.prixUnitairePSoir,
        pourcentageTVA: dataParamsSection1.data.pourcentageTVA,
        droitTimbre: dataParamsSection1.data.droitTimbre,
        pourcentageRSTVA: dataParamsSection1.data.pourcentageRSTVA,
        status: dataParamsSection1.data.status
      })

      dataParamsSection1.data.status && dataParamsSection1.data.status == true ? setButtonText("Modifier") : setButtonText("Valider")
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [buttonText, setButtonText] = useState(status && status == true ? "Modifier" : "Valider");
  const [selectedDate, setSelectedDate] = useState({
    fin: "",
    debut: "",
  });
  const [dataSection2, setDataSection2] = useState({
    annee_fin: "",
    mois_fin: "",
    jour_fin: "",
    annee_debut: "",
    mois_debut: "",
    jour_debut: "",
    ancienPJour: "",
    ancienPetematin: "",
    ancienPNuit: "",
    ancienPSoir: "",
    nouveauPJour: "",
    nouveauPetematin: "",
    nouveauPNuit: "",
    nouveauPSoir: "",
  });

  const handleInfoSection2Change = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setDataSection2((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  function isLater(dateString1, dateString2) {
    return new Date(dateString1) > new Date(dateString2)
  }

  const handleDateChange = (date, type) => {
    var datee = new Date(date);
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
    if (type == "fin") {
      setDataSection2((prevState) => ({
        ...prevState,
        annee_fin: year,
        mois_fin: month,
        jour_fin: day,
      }));
    } else {
      setDataSection2((prevState) => ({
        ...prevState,
        annee_debut: year,
        mois_debut: month,
        jour_debut: day,
      }));
    }
  };
  const dateDebut = dataSection2.jour_debut + " - " + dataSection2.mois_debut + " - " + dataSection2.annee_debut;
  const dateFin = dataSection2.jour_fin + " - " + dataSection2.mois_fin + " - " + dataSection2.annee_fin;
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const generatePDF = (infoSection1, dataSection2) => {
    let energiePjour = parseFloat(dataSection2.nouveauPJour) - parseFloat(dataSection2.ancienPJour)
    let energiePetematin = parseFloat(dataSection2.nouveauPetematin) - parseFloat(dataSection2.ancienPetematin)
    let energiePNuit = parseFloat(dataSection2.nouveauPNuit) - parseFloat(dataSection2.ancienPNuit)
    let energiePSoir = parseFloat(dataSection2.nouveauPSoir) - parseFloat(dataSection2.ancienPSoir)
    let totalEnergie = energiePjour + energiePetematin + energiePNuit + energiePSoir
    let prixPjour = truncateToDecimals(energiePjour * infoSection1.prixUnitairePJour)
    let prixPetematin = truncateToDecimals(energiePetematin * infoSection1.prixUnitairePetematin)
    let prixPnuit = truncateToDecimals(energiePNuit * infoSection1.prixUnitairePNuit)
    let prixPsoir = truncateToDecimals(energiePSoir * infoSection1.prixUnitairePSoir)
    let montantHT = truncateToDecimals(prixPjour + prixPetematin + prixPnuit + prixPsoir)
    let TVA = truncateToDecimals((montantHT * infoSection1.pourcentageTVA) / 100)
    let TTC = truncateToDecimals(montantHT + TVA + infoSection1.droitTimbre)
    let RsMarche = truncateToDecimals((TVA * infoSection1.pourcentageRSTVA) / 100)
    let netpayer = truncateToDecimals(TTC - RsMarche)
    let HTenLettre = toWords.convert(montantHT)
    let TTCenLettre = toWords.convert(TTC)

    let today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();
    var dateLe = day + "/" + month + "/" + year
    var doc = new jsPDF();
    var imgData = factureExcedent;
    doc.addImage(imgData, "JPEG", 0, 0, 210, 297);
    doc.setFontSize(11);
    doc.text(infoSection1.district, 155, 32);
    doc.text(dateLe, 173, 43);
    doc.text(infoSection1.district, 145, 43);
    doc.text((infoSection1.tel).toString(), 168, 62);
    doc.text((infoSection1.fax).toString(), 168, 67);
    doc.text(dateDebut, 65, 62);
    doc.text(dateFin, 105, 62);
    var factureNumber = getRandomInt(99999999).toString();
    doc.text(factureNumber, 61, 50);
    var contratNumber = getRandomInt(99999999).toString();
    doc.text(contratNumber, 46, 78);
    doc.text(infoSection1.comptageCompteurPJour, 52, 119);
    doc.text(infoSection1.comptageCompteurPetematin, 52, 126);
    doc.text(infoSection1.comptageCompteurPNuit, 52, 133);
    doc.text(infoSection1.comptageCompteurPSoir, 52, 140);

    doc.text(dataSection2.ancienPJour, 84, 119);
    doc.text(dataSection2.ancienPetematin, 84, 126);
    doc.text(dataSection2.ancienPNuit, 84, 133);
    doc.text(dataSection2.ancienPSoir, 84, 140);

    doc.text(dataSection2.nouveauPJour, 118, 119);
    doc.text(dataSection2.nouveauPetematin, 118, 126);
    doc.text(dataSection2.nouveauPNuit, 118, 133);
    doc.text(dataSection2.nouveauPSoir, 118, 140);

    doc.text(energiePjour.toString(), 156, 119);
    doc.text(energiePetematin.toString(), 156, 126);
    doc.text(energiePNuit.toString(), 156, 133);
    doc.text(energiePSoir.toString(), 156, 140);

    doc.text(totalEnergie.toString(), 164, 146);

    doc.text(energiePjour.toString(), 50, 176);
    doc.text(energiePetematin.toString(), 50, 185);
    doc.text(energiePNuit.toString(), 50, 193);
    doc.text(energiePSoir.toString(), 50, 201);

    doc.text(totalEnergie.toString(), 50, 209);

    doc.text((infoSection1.prixUnitairePJour).toString(), 74, 176);
    doc.text((infoSection1.prixUnitairePetematin).toString(), 74, 185);
    doc.text((infoSection1.prixUnitairePNuit).toString(), 74, 193);
    doc.text((infoSection1.prixUnitairePSoir).toString(), 74, 201);


    doc.text(prixPjour.toString(), 92, 176);
    doc.text(prixPetematin.toString(), 92, 185);
    doc.text(prixPnuit.toString(), 92, 193);
    doc.text(prixPsoir.toString(), 92, 201);


    doc.text(((infoSection1.pourcentageTVA) + "%").toString(), 130, 185);
    doc.text(((infoSection1.pourcentageRSTVA) + "%").toString(), 154, 209);

    doc.text(montantHT.toString(), 168, 176);
    doc.text(TVA.toString(), 168, 185);
    doc.text((infoSection1.droitTimbre).toString(), 168, 193);
    doc.text(TTC.toString(), 168, 201);
    doc.text(RsMarche.toString(), 168, 209);
    doc.text(netpayer.toString(), 168, 215);
    const textWidth = 120;
    const startX = 76;
    const startY = 231;
    const textLines = doc.splitTextToSize(HTenLettre.toString(), textWidth);
    textLines.forEach((line, index) => {
      doc.text(line, startX, startY + index * 4);
    });
    const textWidth2 = 100;
    const startX2 = 89;
    const startY2 = 239;
    const textLines2 = doc.splitTextToSize(TTCenLettre.toString(), textWidth2);
    textLines2.forEach((line, index) => {
      doc.text(line, startX2, startY2 + index * 4);
    });
    doc.text(rib.toString(), 37, 256);

    doc.save("Estimation Facture Excédent PV Mt.pdf");
  };

  function isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] === undefined || obj[key] === null || obj[key] === '')) {
        return true;
      }
    }
    return false;
  }
  const handlePDFsubmit = async (e) => {
    if (isObjectEmpty(infoSection1) == false) {
      if (isObjectEmpty(dataSection2) == false) {
        if (isLater(selectedDate.debut, selectedDate.fin)) {
          toast.error("La date de début doit être anterieur à la date fin");
        } else {
          generatePDF(infoSection1, dataSection2)
          toast.success('Facture générée avec succès')
        }

      }
      else {
        toast.error("les informations de la section 2 sont obligatoires !")

      }

    } else {
      toast.error("les informations de la section 1 sont obligatoires !")

    }

  }

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="factureexcedentPVMT" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Estimation Facture Excédent Photovoltaîque Moyenne Tension </h1>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Section 1 : Données Conservées</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir vos cordonnées de contact et bancaires</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">District</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={district}
                          name="district"
                          placeholder="Saisir votre District..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> N°Tél </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={tel}
                          onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                          placeholder="Saisir votre Tel..."
                          name="tel"
                          onChange={handleInfoSection1Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> N°Fax </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={fax}
                          onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                          name="fax"
                          placeholder="Saisir votre Fax..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> RIB </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={rib}
                          name="rib"
                          placeholder="Saisir votre RIB..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir les données de comptage compteur STEG Photovoltaîque</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Jour</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={comptageCompteurPJour}
                          name="comptageCompteurPJour"
                          placeholder="Pointe Jour..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe été matin</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={comptageCompteurPetematin}
                          name="comptageCompteurPetematin"
                          placeholder="Pointe été matin..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Nuit</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={comptageCompteurPNuit}
                          name="comptageCompteurPNuit"
                          placeholder="Pointe Nuit..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Soir</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={comptageCompteurPSoir}
                          name="comptageCompteurPSoir"
                          placeholder="Pointe Soir..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir les prix unitaires pour l'énergie cédée au STEG en TND</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Jour</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={prixUnitairePJour}
                          name="prixUnitairePJour"
                          placeholder="Pointe Jour..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe été matin</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={prixUnitairePetematin}
                          name="prixUnitairePetematin"
                          placeholder="Pointe été matin..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Nuit</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={prixUnitairePNuit}
                          name="prixUnitairePNuit"
                          placeholder="Pointe Nuit..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Soir</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={prixUnitairePSoir}
                          name="prixUnitairePSoir"
                          placeholder="Pointe Soir..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir les élèments de facturation</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">% TVA</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={pourcentageTVA}
                          name="pourcentageTVA"
                          placeholder="% TVA..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-2 col-lg-2 col-xl-2">% RS au titre de la TVA</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={pourcentageRSTVA}
                          name="pourcentageRSTVA"
                          placeholder="% RS au titre de la TVA..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>
                      <label className="form-label col-md-2 col-lg-2 col-xl-2">Droit de timbre</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="text"
                          className="form-control"
                          value={droitTimbre}
                          name="droitTimbre"
                          placeholder="droit de timbre..."
                          onChange={handleInfoSection1Change}
                        />
                      </div>

                    </div>

                    <div className="row justify-content-end">

                      <div className="col-md-12 col-lg-12 col-xl-2">
                        <button
                          className={`btn ${buttonText == "Modifier" ? "btn-outline-warning" : "btn-outline-primary"} btn-block`}
                          type="submit"
                          onClick={(e) => handleInfoSection1Submit(e)}
                        >
                          <i className={`fa ${buttonText == "Modifier" ? "fa-edit" : "fa-check"} me-2`}></i>
                          {buttonText}
                        </button>
                        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Section 2 : Données Temporaires</h3>
                  </div>
                  <div className="card-body">
                    <div className="row mb-5">
                      <label className="form-label col-md-1 col-lg-1 col-xl-2">
                        Période :
                      </label>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">
                        DU
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-4 ">
                        <DatePicker
                          id={"dateDebut"}
                          value={selectedDate.debut}
                          onChange={(e) => handleDateChange(e, "debut")}
                        />
                      </div>
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">
                        AU
                      </label>
                      <div className="col-md-4 col-lg-4 col-xl-4 ">
                        <DatePicker
                          id={"dateFin"}
                          value={selectedDate.fin}
                          onChange={(e) => handleDateChange(e, "fin")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir les ANCIENS relevés des index d'énergie injectée (KWH)</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Jour</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.ancienPJour}
                          name="ancienPJour"
                          placeholder="Pointe Jour..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe été matin</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.ancienPetematin}
                          name="ancienPetematin"
                          placeholder="Pointe été matin..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe Nuit  </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.ancienPNuit}
                          name="ancienPNuit"
                          placeholder="Pointe Nuit..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe Soir </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.ancienPSoir}
                          name="ancienPSoir"
                          placeholder="Pointe Soir..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="card-header border-bottom border-top">
                        <h6 className="card-title color_blue">Veuillez saisir les NOUVEAUX relevés des index d'énergie injectée (KWH)</h6>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label className="form-label col-md-1 col-lg-1 col-xl-1">Pointe Jour</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.nouveauPJour}
                          name="nouveauPJour"
                          placeholder="Pointe Jour..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe été matin</label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.nouveauPetematin}
                          name="nouveauPetematin"
                          placeholder="Pointe été matin..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe Nuit  </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.nouveauPNuit}
                          name="nouveauPNuit"
                          placeholder="Pointe Nuit..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>

                      <label className="form-label col-md-1 col-lg-1 col-xl-1"> Pointe Soir </label>
                      <div className="form-group col-md-2 col-lg-2 col-xl-2">
                        <input
                          type="number"
                          className="form-control"
                          value={dataSection2.nouveauPSoir}
                          name="nouveauPSoir"
                          placeholder="Pointe Soir..."
                          onChange={handleInfoSection2Change}
                        />
                      </div>
                    </div>


                    <div className="row justify-content-end">

                      <div className="col-md-12 col-lg-12 col-xl-2">
                        <button
                          className={"btn btn-outline-danger btn-block"}
                          type="submit"
                          onClick={(e) => handlePDFsubmit(e)}
                        >
                          <i className="fa fa-file-pdf-o me-2"></i>
                          {"Génerer Facture"}
                        </button>
                        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default EstimationFactureExcedentPVMT;
