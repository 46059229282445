import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Manuel from "../PDF/Manuel.pdf";

function Faq() {

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Aide & Assitance</h1>
                                    </div>
                                </div>

                                <div className="row row-sm">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h3 className="card-title">FAQ's</h3>
                                            </div>
                                            <div className="card-body">
                                                <div aria-multiselectable="true" className="accordion" id="accordion" role="tablist">
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingOne" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapseOne">
                                                                Q1 : Comment puis-je consulter les mesures RMS en temps réel pour toutes les machines de mon parc ? <span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingOne" className="collapse" data-bs-parent="#accordion" id="collapseOne" role="tabpanel">
                                                            <div className="acc-body">
                                                              Accedez à la page “Suivi Temps Réel”. Cette page affiche les données de tension, de courant, de puissance et de facteur de puissance pour chaque machine, vous permettant de suivre les performances énergétiques en temps réel.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingtwo" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapsetwo">
                                                                Q2 : Comment puis-je générer des factures d’estimation de consommation énergétique pour mes machines ? <span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingtwo" className="collapse" data-bs-parent="#accordion" id="collapsetwo" role="tabpanel">
                                                            <div className="acc-body">
                                                            Accedez à la page “Estimation de Facture”. Vous avez deux méthodes de calcul : en utilisant le bouton “Calculer” pour obtenir un tableau d’estimation ou en appuyant sur “Génération Facture” pour créer une facture au format PDF.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingthree" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapsethree">
                                                                Q3 : Où puis-je trouver les données historiques de consommation d’énergie pour mes machines ? <span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingthree" className="collapse" data-bs-parent="#accordion" id="collapsethree" role="tabpanel">
                                                            <div className="acc-body">
                                                            Accedez à la page “Historique”. Vous pouvez sélectionner la machine, la mesure RMS et la plage de dates pour afficher les données historiques.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingfour" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapsefour">
                                                                Q4 : Comment puis-je configurer des alertes pour être informé en cas de dépassement des seuils de tension ou de courant ? <span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingfour" className="collapse" data-bs-parent="#accordion" id="collapsefour" role="tabpanel">
                                                            <div className="acc-body">
                                                            La configuration des alertes se fait sur la page “Gestion Machines”. Vous pouvez définir les seuils de tension et de courant max/min pour chaque machine, ainsi que le numéro du responsable pour les alertes SMS.                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingfive" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapsefive">
                                                                Q5 : Où puis-je consulter l’historique des alertes et leurs détails ?<span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingfive" className="collapse" data-bs-parent="#accordion" id="collapsefive" role="tabpanel">
                                                            <div className="acc-body">
                                                            Accedez à la page “Alertes” pour consulter l’historique des alertes. Cette page affiche la liste des machines alertées, les messages d’alerte, les valeurs de dépassement de seuils, ainsi que les dates et heures d’émission des alertes.                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingsix" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapsesix">
                                                                Q6 : Comment puis-je personnaliser la tarification pour mes factures d’énergie ? <span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingsix" className="collapse" data-bs-parent="#accordion" id="collapsesix" role="tabpanel">
                                                            <div className="acc-body">
                                                            Accedez à la page “Paramètres Factures”. Vous avez le choix entre deux types de tarification : monotarif et par pointe. Vous pouvez spécifier les informations de votre société et les tarifs associés en fonction de vos besoins.                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card mb-4">
                                                        <div className="acc-header" id="headingseven" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapseseven">
                                                                Q7 : Où puis-je visualiser les images réelles de mes machines dans le parc ?<span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingseven" className="collapse" data-bs-parent="#accordion" id="collapseseven" role="tabpanel">
                                                            <div className="acc-body">
                                                            Accedez à la page “Parc Machine”. Chaque machine est représentée par une carte contenant une photo réelle, le nom de la machine, l’état (vert/rouge/gris) et en survolant une carte, les détails énergétiques RMS s’affichent en temps réel.                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="acc-card">
                                                        <div className="acc-header" id="headingeight" role="tab">
                                                            <h5 className="mb-0">
                                                                <a aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse" href="#collapseeight">
                                                                Q8 : Comment puis-je gérer les seuils de tension et de courant pour chaque machine ?<span className="float-end acc-angle"><i className="fe fe-chevron-right"></i></span>
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        <div aria-labelledby="headingeight" className="collapse" data-bs-parent="#accordion" id="collapseeight" role="tabpanel">
                                                            <div className="acc-body">
                                                              Accedez à la page “Gestion Machines”. Vous pouvez définir les seuils maximums de tension et de courant pour chaque machine en cliquant sur le bouton “Éditer”, ce qui vous permet de personnaliser les paramètres pour chaque équipement.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-header border-bottom">
                                                <h3 className="card-title">Manuel de guide d'utilisation </h3>

                                                <a href={Manuel}><i class="fa fa-file-pdf-o" style={{ fontSize: "25px", color: "red", paddingLeft: "10px" }}></i></a>

                                            </div>
                                            <div className='card-body' style={{ height: "105vh" }}>
                                                <iframe
                                                    src={Manuel}
                                                    width="100%"
                                                    height="100%"
                                                    loading='eager'
                                                    allowfullscreen="true"
                                                >

                                                </iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default Faq