import { io } from 'socket.io-client';
import Highcharts from  "highcharts/highstock";
const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT;


var socket = io(ENDPOINT);

async function RealTimeCurrent(socket, todaycurrent1, todaycurrent2, todaycurrent3) {

    Highcharts.setOptions({
    time: {
        useUTC: false
    },
    dataSorting: {
        enabled: true
    }
});
    Highcharts.stockChart('C', {
        chart: {
            events: {
                load: async function () {
                    var c1 = this.series[0];
                    var c2 = this.series[1];
                    var c3 = this.series[2];
                        try {
                            socket.on('data', async function (data) { 
                               
                                if (data.machine.id_machine === localStorage.getItem('machine_selected') ) {
                                    var x = new Date(data.time.time).getTime() - 1 * 60 * 60 * 1000, // current time
                                    y = parseInt(data.current.current1),
                                    z = parseInt(data.current.current2),
                                    a = parseInt(data.current.current3);
                                    
                                    c1.addPoint([x, y], true, true);
                                    c2.addPoint([x, z], true, true);
                                    c3.addPoint([x, a], true, true);
                                }
                                
                            });
                        } catch (error) {
                        console.log(error)

                        }

                }
            }, 
            zoomType: 'x',  
        },
      

        
	time: {
            useUTC: false
        },

        rangeSelector: {
            buttons: [{
                type: 'all',
                text: '24H'
            }],
            inputEnabled: false,
            selected: 2
        },

        title: {
            text: ''
        },

        tooltip: {
            pointFormat: "{series.name} : {point.y:.2f}"
        },

        exporting: {
            enabled: true
        },
        dataSorting: {
            enabled: true
    	},

        xAxis: {
            type: 'datetime',
            range: 180000,
        },

        series: [
            {
            type: "spline",
            name: 'C1',
            color: '#000000', 
            data: (function () {
                var data = [],
                time = (new Date()).getTime(),
                i;

            for (i = -todaycurrent1.length; i < 0; i += 1) {
                data.push([
                    todaycurrent1[todaycurrent1.length + i][0],
                    todaycurrent1[todaycurrent1.length + i][1]
                ]);
            }
            return data;
            }()),
        },
        {
            type: "spline",
            name: 'C2',
            color: '#EF2C2F',
            data: (function () {
                // generate an array of random data
                var data = [],
                time = (new Date()).getTime(),
                i;

            for (i = -todaycurrent2.length; i < 0; i += 1) {
                data.push([
                    todaycurrent2[todaycurrent2.length + i][0],
                    todaycurrent2[todaycurrent2.length + i][1]
                ]);
            }
            return data;
            }()),
        },
        {
            type: "spline",
            name: 'C3',
            color: '#0952E3', 
            data: (function () {
                // generate an array of random data
                var data = [],
                time = (new Date()).getTime(),
                i;

            for (i = -todaycurrent3.length; i < 0; i += 1) {
                data.push([
                    todaycurrent3[todaycurrent3.length + i][0],
                    todaycurrent3[todaycurrent3.length + i][1]
                ]);
            }
            return data;
            }()),
        },

        ]
    });
}


export default RealTimeCurrent
