import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import EnercoInput from "../components/EnercoInput.js"
import PasswordInput from "../components/PasswordInput.js";
function Register() {

    const [values, setValues] = useState({
        email: "",
        password: "",
        nom: "",
        nom_utilisateur: "",
        usines: "",
        role: "",
    })
    const [data, setData] = useState({ testImage: "" });
    const { testImage } = data
    const navigate = useNavigate();

    const generateError = (error) =>
        toast.error(error, {
            position: "top-right",
        });
    const generateSuccess = (message) =>
        toast.success(message, {
            position: "top-right",
        });
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Assumes there is at least one file
        if (file) { // It's a good practice to check if the file exists before checking its type
            if (file.type === "image/jpeg" || file.type === "image/png") {
                setData((prevState) => ({
                    ...prevState,
                    testImage: file,
                }));
            } else {
                toast.warning("Seulement les images de types JPEG ou PNG sont autorisées.");
            }
        } else {
            // Optionally handle cases where no file was selected
            toast.warning("Aucun fichier sélectionné.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('nom_utilisateur', values.nom_utilisateur);
        formData.append('nom', values.nom);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('usines', values.usines);
        formData.append('role', values.role);
        formData.append('testImage', testImage);
        var email = values.email;

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/register`,
                formData
            );
            if (data.created) {
                toast.success(data.message);
                localStorage.setItem('jwt', data.token);
                navigate("/verify-email", { state: { email } });
            } else if (data.errors) {
                // handle errors
                const { email, password } = data.errors;
                if (email) generateError(email);
                else if (password) generateError(password);
            }
        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue.");
        }
    };


    return (
        <div className="page-register">
            <div className="container-register">
                <div className="wrap-register">

                    <div className="container">
                        <div className="register-pic">
                            <img src="/assets/images/login/logo-enerco2.png" alt="logo" />
                        </div>
                        <span className="connexion-label">Inscription</span>
                    </div>
                    <form className="register-form validate-form pt-2" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                                <EnercoInput
                                    label="Nom société"
                                    type="text"
                                    name="nom"
                                    value={values.nom}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                                <EnercoInput
                                    label="Nom & Prénom utilisateur"
                                    type="text"
                                    name="nom_utilisateur"
                                    value={values.nom_utilisateur}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6 ">
                                <EnercoInput
                                    label="Adresse Email"
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                                <PasswordInput
                                    label="Mot de passe"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                                <EnercoInput
                                    label="Code Usine"
                                    type="text"
                                    name="usines"
                                    value={values.usines}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                                <EnercoInput
                                    label="LOGO l'usine"
                                    type="file"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png"
                                />
                            </div>
                        </div>
                        <div className="wrap-register validate-input">
                            <div className="row">
                                <div className="col-md-6 custom-checkbox-container">
                                    <input
                                        type="radio"
                                        value="0"
                                        name="role"
                                        id="admin"
                                        checked={values.role === "0"}
                                        onChange={(e) => setValues({ ...values, role: e.target.value })}
                                    />
                                    <label className="custom-checkbox" htmlFor="admin">Admin</label>
                                </div>
                                <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6 custom-checkbox-container">
                                    <input
                                        type="radio"
                                        value="1"
                                        name="role"
                                        id="user"
                                        checked={values.role === "1"}
                                        onChange={(e) => setValues({ ...values, role: e.target.value })}
                                    />
                                    <label className="custom-checkbox" htmlFor="user">Utilisateur</label>
                                </div>
                            </div>
                        </div>

                        <div className="container-register-form-btn">
                            <button type="submit" className="register-form-btn">S'inscrire</button>
                            <ToastContainer
                                className="toast-container"
                                autoClose={2000}
                                closeButton={false}
                                theme={"colored"}
                                icon={true}
                            />
                        </div>
                    </form>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/elctrosoft">
                            <img src="/assets/images/login/facebook.png" alt="Facebook" />
                        </a>
                        <a href="http://enerco.electrosoft.tn:3000">
                            <img src="/assets/images/login/apk.png" alt="apk" />
                        </a>
                        <a href="https://electrosoft.com.tn">
                            <img src="/assets/images/login/site.png" alt="Google" />
                        </a>
                    </div>
                    <div className="create-account">
                        <span> Vous avez déjà un compte ? </span><a href="/login">Se connecter</a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Register