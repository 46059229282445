import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import SortIcon from "@material-ui/icons/ArrowDownward";


function AlertesCosphi() {
	const [alertData, setAlertData] = useState([])
	const columns = [
		{
			key: 'Machine',
			name: 'Machine',
			selector: row => row.nom_machine,
			sortable: true,
			reorder: true,
		},
		{
			key: 'Message',
			name: 'Message',
			selector: row => row.message,
			sortable: true,
			reorder: true,
			width: "55%"
		},
		{
			key: 'Valeur',
			name: 'Valeur',
			selector: row => Math.round(row.value * 100) / 100,
			sortable: true,
			reorder: true,
		},
		{
			key: 'Date',
			name: 'Date',
			selector: row => dateFormat(row.date),
			sortable: true,
			reorder: true,
		}
	];

	const paginationComponentOptions = {
		rowsPerPageText: 'Machines par page : ',
		rangeSeparatorText: 'De',
		selectAllRowsItem: true,
		selectAllRowsItemText: "Tous"
	};

	const dateFormat = (format) => {
		if (format != undefined) {
			const date = new Date(format);

			//extract the parts of the date
			let day = date.getDate();
			let month = date.getMonth() + 1;
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			const year = date.getFullYear();
			let string = date.toISOString();
			let time = string.match(/\d\d:\d\d/);

			return day + "-" + month + "-" + year + " " + time;
		}

	}
	useEffect(() => {
		async function fetchparams() {
			let alertsParams;
			try {
				alertsParams = await axios.get(
					process.env.REACT_APP_BACKEND_IP
					+ ':' + process.env.REACT_APP_BACKEND_PORT
					+ '/alerts/getAlertsCosphi'
				)
				setAlertData(alertsParams.data)
			} catch (error) {
				console.log(error)
			}
		}
		fetchparams();
	}, [])

	return (
		<>
			<div className="page">
				<div className="page-main">
					<Header />
					<Sidebar activeItem="alertesCosphi" />
					<div className="app-content main-content mt-20">
						<div className="side-app">
							<div className="main-container container-fluid">
								<div className="page-header">
									<div>
										<h1 className="page-title">Historique des alertes du Facteur de puissance (Cosphi)</h1>
									</div>
								</div>

								<div className="row row-sm">
									<div className="col-lg-12">
										<div className="card">
											<div className="card-body">
												<DataTable
													data={alertData}
													columns={columns}
													sortIcon={<SortIcon />}
													defaultSortFieldId={1}
													pagination
													paginationPerPage={10}
													paginationTotalRows={alertData.length}
													striped={true}
													paginationComponentOptions={paginationComponentOptions}
													fixedHeader
													fixedHeaderScrollHeight="60vh"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
export default AlertesCosphi