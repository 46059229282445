import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import DatePicker from '../components/DatePicker';
import TimePicker from '../components/TimePicker';
import SelectOne from '../components/SelectOne';
import ReactHighcharts from 'react-highcharts/ReactHighstock.src';
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Historique() {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const CourbeTypes = [
    {
      label: "Puissance",
      value: "Puissance"
    },
    {
      label: "Courant",
      value: "Courant"
    },
    {
      label: "Tension",
      value: "Tension"
    },
    {
      label: "Facteur de Puissance",
      value: "Facteur de Puissance"
    },
  ]
  const [listMachine, setListMachine] = useState([])
  const [courbe, setCourbe] = useState([])
  const [messagePuissance, setMessagePuissance] = useState({
    totalLigne1: '',
    totalLigne2: '',
    totalLigne3: ''
  })
  const [selectedDate, setSelectedDate] = useState({
    fin: '',
    debut: ''
  });
  const [selected, setSelected] = useState({
    courbe: '',
    machines: '',
    fin: '',
    debut: '',
    timeDebut: '',
    timeFin: '',
    titleChart: '',
  }

  )
  const [showCard, setShowCard] = useState(false)
  const [chartDataL1, setChartDataL1] = useState([])
  const [chartDataL2, setChartDataL2] = useState([])
  const [chartDataL3, setChartDataL3] = useState([])
  const [selectedTime, setSelectedTime] = useState({
    timeDebut: '',
    timeFin: ''
  });

  const [isLoading, setIsLoading] = useState(false);

  const secDiff = 30 * 1000;
  const insertZeroValueTimestamp = (timestamps) => {
    const newTimestamps = [];
    for (let i = 0; i < timestamps.length - 1; i++) {
      newTimestamps.push(timestamps[i]);
      const timeDiff = timestamps[i + 1][0] - timestamps[i][0];
      if (timeDiff > secDiff) {
        let nextTimestamp = timestamps[i][0] + secDiff;
        while (nextTimestamp < timestamps[i + 1][0]) {
          // Check if there is already a value in this date
          const index = timestamps.findIndex(([timestamp]) => timestamp === nextTimestamp);
          if (index === -1) {
            newTimestamps.push([nextTimestamp, 0]);
          }
          nextTimestamp += secDiff;
        }
      }
    }
    newTimestamps.push(timestamps[timestamps.length - 1]);
    return newTimestamps;
  };


  const [msgNoData, setMsgNoData] = useState(null)
  useEffect(() => {
    setListMachine(JSON.parse(localStorage.getItem('machines')));
    setCourbe(CourbeTypes)
  }, [chartDataL1, chartDataL2, chartDataL3])


  const handleChange = (event, type) => {
    if (type === 'courbe' || type === "machines") {
      if (chartDataL1.length !== 0 && chartDataL2.length !== 0 && chartDataL3.length !== 0 ){
        setShowCard(false);
        setChartDataL1([]);
        setChartDataL2([]);
        setChartDataL3([]);
      }  
    }
    setSelected(prevState => ({ ...prevState, [type]: event.value }));
};


  function isLater(dateString1, dateString2) {
    return new Date(dateString1) > new Date(dateString2)
  }

  function isLaterTime(dateString1, dateString2) {
    return dateString1 > dateString2
  }
  const handleCard = () => {
    setShowCard(true)
  }
  const handleErrors = (e) => {
    if (selected.courbe === "") {
      toast.error("Veuillez sélectionner courbe");
    }
    else if (selected.machines === "") {
      toast.error("Veuillez sélectionner machine");
    }
    else if (selected.debut === "") {
      toast.error("Veuillez sélectionner date de début");
    }
    else if (selected.fin === "") {
      toast.error("Veuillez sélectionner date de fin");
    }
    // else if ((isLater(selected.debut, selected.fin))) {
    //   toast.error("La date de début doit être anterieur a la date fin")
    // }
    // else if ((isLaterTime(selected.timeDebut, selected.timeFin))) {
    //   toast.error("Le temps de début doit être anterieur au temps de fin")
    // } 
    else {
      handleSubmit(e);
    }
  }


  const handleDateChange = (date, type) => {
    var datee = new Date(date)
    var day = datee.getDate();
    var month = datee.getMonth() + 1;
    var year = datee.getFullYear();

    if (day < 10) {
      day = "0" + day
    }
    if (month < 10) {
      month = "0" + month
    }

    var new_date = month + '/' + day + '/' + year
    setSelectedDate(prevState => ({ ...prevState, [type]: datee }));
    setSelected(prevState => ({ ...prevState, [type]: new_date }));
    if (chartDataL1.length !== 0 && chartDataL2.length !== 0 && chartDataL3.length !== 0 ){
      setShowCard(false);
      setChartDataL1([]);
      setChartDataL2([]);
      setChartDataL3([]);
    }  
  };


  const handleTimeChange = (date, type) => {
    var time = new Date(date)
    var hour = time.getHours();
    var minutes = time.getMinutes();
    if (hour < 10) {
      hour = "0" + hour
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    var new_time = hour + ':' + minutes
    setSelectedTime(prevState => ({ ...prevState, [type]: time }));
    setSelected(prevState => ({ ...prevState, [type]: new_time }));
    if (chartDataL1.length !== 0 && chartDataL2.length !== 0 && chartDataL3.length !== 0 ){
      setShowCard(false);
      setChartDataL1([]);
      setChartDataL2([]);
      setChartDataL3([]);
    }  
  };


  const handleSubmit = async (e) => {
    
    e.preventDefault()

    setMessagePuissance({
      totalLigne1: '',
      totalLigne2: '',
      totalLigne3: ''
    })


    var totalPower1 = 0;
    var totalPower2 = 0;
    var totalPower3 = 0;
    var time_debut = "";
    var time_fin = "";

      try {
        if(selected.timeDebut==""){
          time_debut = "00:00";
        }else {
          time_debut = selected.timeDebut;
        }
        if(selected.timeFin==""){
          var time = new Date()
          var hour = time.getHours();
          var minutes = time.getMinutes()-1;
          if (hour < 10) {
            hour = "0" + hour
          }
          if (minutes < 10) {
            minutes = "0" + minutes
          }
          var new_time = hour + ':' + minutes
          time_fin  = new_time;
        }
        else {
          time_fin = selected.timeFin;
        }
        const response = await axios.get(process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/historique/checkDates/'
          + selected.debut + '/'
          + time_debut + '/'
          + selected.fin + '/'
          + time_fin,
          {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
        );
        if (response.status === 200) {
          setIsLoading(true);
          try {
            const config = {
              headers: {
                'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
              }
            };
                //getHistoryDataTime
            if (selected.timeDebut !== "" && selected.timeFin !== ""  && selected.debut === selected.fin) {
              if (selected.courbe === "Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataPower1, chartDataPower2, chartDataPower3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataPower1 = insertZeroValueTimestamp([...chartDataPower1.data]);
                let updatedChartDataPower2 = insertZeroValueTimestamp([...chartDataPower2.data]);
                let updatedChartDataPower3 = insertZeroValueTimestamp([...chartDataPower3.data]);
                setChartDataL1(updatedChartDataPower1)
                setChartDataL2(updatedChartDataPower2)
                setChartDataL3(updatedChartDataPower3)
                setIsLoading(false)

                if (chartDataPower1.data.length > 0) {
                  chartDataPower1.data.map(element => {
                    totalPower1 += element[1];
                  })
                }
                if (chartDataPower2.data.length > 0) {
                  chartDataPower2.data.map(element => {
                    totalPower2 += element[1];
                  })
                }
                if (chartDataPower3.data.length > 0) {
                  chartDataPower3.data.map(element => {
                    totalPower3 += element[1];
                  })
                }

                setMessagePuissance({
                  totalLigne1: Number((totalPower1/1000).toFixed(3)),
                  totalLigne2: Number((totalPower2/1000).toFixed(3)),
                  totalLigne3: Number((totalPower3/1000).toFixed(3))
                })

              }
              else if (selected.courbe === "Courant") {
                selected.titleChart = selected.courbe
                const [chartDataCurrent1, chartDataCurrent2, chartDataCurrent3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataCurrent1 = insertZeroValueTimestamp([...chartDataCurrent1.data]);
                let updatedChartDataCurrent2 = insertZeroValueTimestamp([...chartDataCurrent2.data]);
                let updatedChartDataCurrent3 = insertZeroValueTimestamp([...chartDataCurrent3.data]);
                setChartDataL1(updatedChartDataCurrent1)
                setChartDataL2(updatedChartDataCurrent2)
                setChartDataL3(updatedChartDataCurrent3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Tension") {
                selected.titleChart = selected.courbe
                const [chartDataVotage1, chartDataVotage2, chartDataVotage3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  )
                ])

                let updatedChartDataVotage1 = insertZeroValueTimestamp([...chartDataVotage1.data]);
                let updatedChartDataVotage2 = insertZeroValueTimestamp([...chartDataVotage2.data]);
                let updatedChartDataVotage3 = insertZeroValueTimestamp([...chartDataVotage3.data]);
                setChartDataL1(updatedChartDataVotage1)
                setChartDataL2(updatedChartDataVotage2)
                setChartDataL3(updatedChartDataVotage3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Facteur de Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataCosphi1, chartDataCosphi2, chartDataCosphi3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataCosphi1 = insertZeroValueTimestamp([...chartDataCosphi1.data]);
                let updatedChartDataCosphi2 = insertZeroValueTimestamp([...chartDataCosphi2.data]);
                let updatedChartDataCosphi3 = insertZeroValueTimestamp([...chartDataCosphi3.data]);
                setChartDataL1(updatedChartDataCosphi1)
                setChartDataL2(updatedChartDataCosphi2)
                setChartDataL3(updatedChartDataCosphi3)
                setIsLoading(false)

              }
            }
            //getDataForDateTimeRange
            else if (selected.timeDebut !== "" && selected.timeFin !== "" && selected.debut !== selected.fin){
              if (selected.courbe === "Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataPower1, chartDataPower2, chartDataPower3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  )
                ])

                let updatedChartDataPower1 = insertZeroValueTimestamp([...chartDataPower1.data]);
                let updatedChartDataPower2 = insertZeroValueTimestamp([...chartDataPower2.data]);
                let updatedChartDataPower3 = insertZeroValueTimestamp([...chartDataPower3.data]);
                setChartDataL1(updatedChartDataPower1)
                setChartDataL2(updatedChartDataPower2)
                setChartDataL3(updatedChartDataPower3)
                setIsLoading(false)

                if (chartDataPower1.data.length > 0) {
                  chartDataPower1.data.map(element => {
                    totalPower1 += element[1];
                  })
                }
                if (chartDataPower2.data.length > 0) {
                  chartDataPower2.data.map(element => {
                    totalPower2 += element[1];
                  })
                }
                if (chartDataPower3.data.length > 0) {
                  chartDataPower3.data.map(element => {
                    totalPower3 += element[1];
                  })
                }

                setMessagePuissance({
                  totalLigne1: Number(totalPower1.toFixed(3)),
                  totalLigne2: Number(totalPower2.toFixed(3)),
                  totalLigne3: Number(totalPower3.toFixed(3))
                })

              }
              else if (selected.courbe === "Courant") {
                selected.titleChart = selected.courbe
                const [chartDataCurrent1, chartDataCurrent2, chartDataCurrent3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataCurrent1 = insertZeroValueTimestamp([...chartDataCurrent1.data]);
                let updatedChartDataCurrent2 = insertZeroValueTimestamp([...chartDataCurrent2.data]);
                let updatedChartDataCurrent3 = insertZeroValueTimestamp([...chartDataCurrent3.data]);
                setChartDataL1(updatedChartDataCurrent1)
                setChartDataL2(updatedChartDataCurrent2)
                setChartDataL3(updatedChartDataCurrent3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Tension") {
                selected.titleChart = selected.courbe
                const [chartDataVotage1, chartDataVotage2, chartDataVotage3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataVotage1 = insertZeroValueTimestamp([...chartDataVotage1.data]);
                let updatedChartDataVotage2 = insertZeroValueTimestamp([...chartDataVotage2.data]);
                let updatedChartDataVotage3 = insertZeroValueTimestamp([...chartDataVotage3.data]);
                setChartDataL1(updatedChartDataVotage1)
                setChartDataL2(updatedChartDataVotage2)
                setChartDataL3(updatedChartDataVotage3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Facteur de Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataCosphi1, chartDataCosphi2, chartDataCosphi3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi1/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi2/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi3/'
                    + selected.debut + '/'
                    + selected.timeDebut + '/'
                    + selected.fin + '/'
                    + selected.timeFin,
                    config
                  )
                ])
                let updatedChartDataCosphi1 = insertZeroValueTimestamp([...chartDataCosphi1.data]);
                let updatedChartDataCosphi2 = insertZeroValueTimestamp([...chartDataCosphi2.data]);
                let updatedChartDataCosphi3 = insertZeroValueTimestamp([...chartDataCosphi3.data]);
                setChartDataL1(updatedChartDataCosphi1)
                setChartDataL2(updatedChartDataCosphi2)
                setChartDataL3(updatedChartDataCosphi3)
                setIsLoading(false)

              }
            }
            //getHistoryData
            else if (selected.timeDebut === "" && selected.timeFin === ""){
              if (selected.courbe === "Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataPower1, chartDataPower2, chartDataPower3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power1/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power2/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/power3/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  )
                ])

                let updatedChartDataPower1 = insertZeroValueTimestamp([...chartDataPower1.data]);
                let updatedChartDataPower2 = insertZeroValueTimestamp([...chartDataPower2.data]);
                let updatedChartDataPower3 = insertZeroValueTimestamp([...chartDataPower3.data]);
                setChartDataL1(updatedChartDataPower1)
                setChartDataL2(updatedChartDataPower2)
                setChartDataL3(updatedChartDataPower3)
                setIsLoading(false)

                if (chartDataPower1.data.length > 0) {
                  chartDataPower1.data.map(element => {
                    totalPower1 += element[1];
                  })
                }
                if (chartDataPower2.data.length > 0) {
                  chartDataPower2.data.map(element => {
                    totalPower2 += element[1];
                  })
                }
                if (chartDataPower3.data.length > 0) {
                  chartDataPower3.data.map(element => {
                    totalPower3 += element[1];
                  })
                }
                setMessagePuissance({
                  totalLigne1: Number((totalPower1/1000).toFixed(3)),
                  totalLigne2: Number((totalPower2/1000).toFixed(3)),
                  totalLigne3: Number((totalPower3/1000).toFixed(3))
                })

              }
              else if (selected.courbe === "Courant") {
                selected.titleChart = selected.courbe
                const [chartDataCurrent1, chartDataCurrent2, chartDataCurrent3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current1/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current2/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/current3/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  )
                ])
                let updatedChartDataCurrent1 = insertZeroValueTimestamp([...chartDataCurrent1.data]);
                let updatedChartDataCurrent2 = insertZeroValueTimestamp([...chartDataCurrent2.data]);
                let updatedChartDataCurrent3 = insertZeroValueTimestamp([...chartDataCurrent3.data]);
                setChartDataL1(updatedChartDataCurrent1)
                setChartDataL2(updatedChartDataCurrent2)
                setChartDataL3(updatedChartDataCurrent3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Tension") {
                selected.titleChart = selected.courbe
                const [chartDataVotage1, chartDataVotage2, chartDataVotage3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage1/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage2/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/voltage3/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  )
                ])
                let updatedChartDataVotage1 = insertZeroValueTimestamp([...chartDataVotage1.data]);
                let updatedChartDataVotage2 = insertZeroValueTimestamp([...chartDataVotage2.data]);
                let updatedChartDataVotage3 = insertZeroValueTimestamp([...chartDataVotage3.data]);
                setChartDataL1(updatedChartDataVotage1)
                setChartDataL2(updatedChartDataVotage2)
                setChartDataL3(updatedChartDataVotage3)
                setIsLoading(false)

              }

              else if (selected.courbe === "Facteur de Puissance") {
                selected.titleChart = selected.courbe
                const [chartDataCosphi1, chartDataCosphi2, chartDataCosphi3] = await axios.all([
                  axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi1/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi2/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  ),
                  await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/historique/'
                    + selected.machines + '/cosphi3/'
                    + selected.debut + '/'
                    + selected.fin,
                    config
                  )
                ])
                let updatedChartDataCosphi1 = insertZeroValueTimestamp([...chartDataCosphi1.data]);
                let updatedChartDataCosphi2 = insertZeroValueTimestamp([...chartDataCosphi2.data]);
                let updatedChartDataCosphi3 = insertZeroValueTimestamp([...chartDataCosphi3.data]);
                setChartDataL1(updatedChartDataCosphi1)
                setChartDataL2(updatedChartDataCosphi2)
                setChartDataL3(updatedChartDataCosphi3)
                setIsLoading(false)

              }

            }
            else {
              console.log("Condition not found !")
            }
            handleCard()
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  
        } 

    } catch (error) {
      if (error.response && error.response.status === 400) {
        const response = error.response.data;
        // Handle validation errors
        if (response.date1IsBeforedate2 === false) {
          toast.error("Le temps de début doit être antérieur au temps de fin");
        } 
        else if (response.date1IsBeforeNow === false) {
          toast.error("Le temps de début doit être antérieur au temps actuelle");
        } else if (response.date2IsBeforeNow === false) {
          toast.error("Le temps de fin doit être antérieur au temps actuelle");
        } else {
          toast.error("Invalid dates provided.");
        }
      } else {
        console.log(error);
        toast.error("Une erreur s'est produite lors de la vérification des dates");
      }
    }
    
  }

  const Cards = () => {
    if (chartDataL1.length === 0 || chartDataL2.length === 0 || chartDataL3.length === 0) {
      setMsgNoData("Pas de donnée disponible !")
    }
    else {
      setMsgNoData(null)
    }

    const configOptions = {
      chart: {
        zoomType: 'x',

      },
      title: {
        text: (messagePuissance.totalLigne1 !== "") ? selected.titleChart + " L1 : " + messagePuissance.totalLigne1 + " Kw" : selected.titleChart + " L1",
      },
      xAxis: {
        type: 'datetime'
      },

      rangeSelector: {
        selected: 3,
        inputEnabled: true,
        buttons: [{
          type: 'day',
          count: 1,
          text: '1j'
        }, {
          type: 'week',
          count: 1,
          text: '1s'
        }, {
          type: 'month',
          count: 1,
          text: '1m'
        }, {
          type: 'all',
          text: 'Tous'
        }]
      },
      tooltip: {
        pointFormat: `${selected.titleChart}` + ' L1 : {point.y:.3f}',
      },

      series: [{
        name: selected.titleChart,
        data: chartDataL1,
        type: "spline",
        color: '#000000',
        lineWidth: 1
      }]
    };

    const configOptions2 = {
      chart: {
        zoomType: 'x',

      },
      title: {
        text: (messagePuissance.totalLigne2 !== "") ? selected.titleChart + " L2 : " + messagePuissance.totalLigne2 + " Kw" : selected.titleChart + " L2",
      },
      xAxis: {
        type: 'datetime'
      },

      rangeSelector: {
        selected: 3,
        inputEnabled: true,
        buttons: [{
          type: 'day',
          count: 1,
          text: '1j'
        }, {
          type: 'week',
          count: 1,
          text: '1s'
        }, {
          type: 'month',
          count: 1,
          text: '1m'
        }, {
          type: 'all',
          text: 'Tous'
        }]
      },
      tooltip: {
        pointFormat: `${selected.titleChart}` + ' L2 : {point.y:.3f}',
      },
      series: [{
        name: selected.titleChart,
        data: chartDataL2,
        type: "spline",
        color: '#EF2C2F',
        lineWidth: 1
      }]
    };

    const configOptions3 = {
      chart: {
        zoomType: 'x',

      },
      title: {
        text: (messagePuissance.totalLigne3 !== "") ? selected.titleChart + " L3 : " + messagePuissance.totalLigne3 + " Kw" : selected.titleChart + " L3",
      },
      xAxis: {
        type: 'datetime'
      },


      rangeSelector: {
        selected: 3,
        inputEnabled: true,
        buttons: [{
          type: 'day',
          count: 1,
          text: '1j'
        }, {
          type: 'week',
          count: 1,
          text: '1s'
        }, {
          type: 'month',
          count: 1,
          text: '1m'
        }, {
          type: 'all',
          text: 'Tous'
        }]
      },
      tooltip: {
        pointFormat: `${selected.titleChart}` + ' L3 : {point.y:.3f}',
      },
      series: [{
        name: selected.titleChart,
        data: chartDataL3,
        type: "spline",
        color: '#0952E3',
        lineWidth: 1
      }]
    };


    return (
      msgNoData === null ?
        <div className="card">
          <div className="card-header">
          </div>
          <div id='wrapper'>
            <div id='chart-line'>
              <ReactHighcharts config={configOptions} ></ReactHighcharts>
            </div>
            <div id='chart-line2'>
              <ReactHighcharts config={configOptions2} ></ReactHighcharts>
            </div>
            <div id='chart-line3'>
              <ReactHighcharts config={configOptions3} ></ReactHighcharts>
            </div>
          </div>
        </div>
        : <div className='card'>  <div className="card-header">
          <b> {msgNoData} </b></div></div>
    );


  }

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="historique" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Historique</h1>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom">
                    <h3 className="card-title">Sélectionnez vos paramètres </h3>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <label className="form-label col-md-2 col-lg-2 col-xl-1"> Courbe</label>
                      <div className="form-group col-md-4 col-lg-4 col-xl-4">
                        <SelectOne
                          placeholder={"Sélectionnez courbe ..."}
                          options={
                            courbe.map(element => (
                              { value: element.value, label: element.label }
                            )
                            )}
                          onChange={(e) => handleChange(e, 'courbe')}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />
                      </div>
                      <label className="form-label col-md-2 col-lg-2 col-xl-1">Machine</label>
                      <div className="form-group col-md-4 col-lg-4 col-xl-4">

                        <SelectOne
                          placeholder={"Sélectionnez machine ..."}
                          options={
                            listMachine.map(element => (
                              { value: element.machine_id, label: element.machine_name }
                            )
                            )}
                          onChange={(e) => handleChange(e, 'machines')}
                          menuPlacement="auto"
                          menuPosition="fixed"
                          autosize={true}
                        />

                      </div>


                    </div>
                    <div className='row'>
                      <label className="form-label col-md-3 col-lg-3 col-xl-1">Date</label>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <DatePicker
                          id={"dateDebut"}
                          value={selectedDate.debut}
                          onChange={(e) => handleDateChange(e, 'debut')}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <DatePicker
                          id={"dateFin"}
                          value={selectedDate.fin}
                          onChange={(e) => handleDateChange(e, 'fin')}
                        />
                      </div>

                      <label className="form-label col-md-3 col-lg-3 col-xl-1">Temps</label>
                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <TimePicker
                          id={"timeDebut"}
                          value={selectedTime.timeDebut}
                          onChange={(e) => handleTimeChange(e, 'timeDebut')}
                          // disabled={(selected.debut === selected.fin) ? false : true}

                        />
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2 ">
                        <TimePicker
                          id={"timeFin"}
                          value={selectedTime.timeFin}
                          onChange={(e) => handleTimeChange(e, 'timeFin')}
                          // disabled={(selected.debut === selected.fin) ? false : true}
                        />
                      </div>

                      <div className='col-md-12 col-lg-12 col-xl-2'>
                        <button className="btn btn-outline-primary btn-block" type="submit" onClick={(e) => handleErrors(e)}>
                          <i className="fa fa-area-chart me-2"></i>Valider
                        </button>
                        <ToastContainer
                          className="toast-container"
                          autoClose={4000}
                          closeButton={false}
                          theme={"colored"}
                          icon={true}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  {isLoading ? (
                    <BouncingDotsLoader />
                  ) : showCard ? <Cards /> : <></>} </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Historique