import React from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';


const CheckEmail = () => {
  const location = useLocation();
  const { email } = location.state || { email: 'No email provided' };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/forgot-password`, { email: email});        
        console.log('Response:', response.data);
        toast.success('Email renvoyé avec succée');
    } catch (error) {
        if (error.response && error.response.status === 500) {
            toast.error('Email introuvable');
        } else {
          console.error(error.response);
            toast.error(error.response.data);
        }
    }
};

  return (
    <div className="page-register">
    <div className="container-check-email">
      <div className="icon-check-email">
      <img src="/assets/images/login/mail.png" alt="Envelope" className="envelope-image" />
      </div>
      <h1 className="heading-check-email">Consultez e-mail</h1>
      <p className="text-check-email">
      Veuillez consulter l'adresse électronique <span className="email-address">{email}</span> pour obtenir des instructions sur la réinitialisation de votre mot de passe.
      </p>
      <button className="button-resend" onClick={handleResend}>Renvoyer l'e-mail</button>
    </div>
    <ToastContainer
        className="toast-container"
        autoClose={2000}
        closeButton={false}
        theme={"colored"}
        icon={true}
    />
    </div>

  );
};

export default CheckEmail;
