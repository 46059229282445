import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import SelectBox from '../components/SelectBox';
import Chart from "react-apexcharts";
import axios from 'axios';
import BouncingDotsLoader from '../components/BouncingDotsLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BilanEnergie() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    let defaultValue = ""
    const selectAllOption = { label: 'Sélectionnez tout', value: '*' };
    const options = [];
    function setOptions() {
        const listMachines = JSON.parse(localStorage.getItem('machines'));
        Object.keys(listMachines).map(index => {
            var details = {
                value: listMachines[index].machine_id,
                label: listMachines[index].machine_name
            }
            options.push(details);
        })

    }

    setOptions();

    const initialVisibleOptions = (options.length === defaultValue?.length) ? options : [selectAllOption, ...options];
    const [availableOptions, setAvailableOptions] = useState(initialVisibleOptions);
    const [selectedValues, setSelectedValues] = useState(defaultValue);
    const [selectedVal, setSelectedVal] = useState([])
    const [chartDataPerDay, setChartDataPerDay] = useState('')
    const [chartDataPerMonth, setChartDataPerMonth] = useState('')
    const [chartTimes, setChartTimes] = useState('')
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd] = useState('')
    const [showCard, setShowCard] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (newSelectedOptions) => {
        const selectAllIsSelected = !!newSelectedOptions.find(
            o => o.value === selectAllOption.value,
        );

        const newComponentState = selectAllIsSelected
            ? {
                selectedValues: options,
                availableOptions: [],
            }
            : {
                selectedValues: newSelectedOptions,
                availableOptions: initialVisibleOptions,
            };

        setSelectedValues(newComponentState.selectedValues);
        setAvailableOptions(newComponentState.availableOptions);
        // onChange(newComponentState.selectedValues);
    };
    const handleErrors = () => {
        if (Object.keys(selectedVal).length === 0) {
            toast.error("Veuillez sélectionner au moins une machine");
        }

    }
    const handleCard = () => {
        setShowCard(true)
    }
    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let chartBarData;
        if (Object.keys(selectedVal).length !== 0) {
            try {
                chartBarData = await axios.get(
                    process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/bilan/getDataChartBilan/'
                    + selectedVal ,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                );
                setChartDataPerDay(chartBarData.data.data)
                setChartTimes(chartBarData.data.times)
                setChartDataPerMonth(chartBarData.data.sum)
                setDateStart(chartBarData.data.full_date_start)
                setDateEnd(chartBarData.data.full_date_end)
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false);
            handleCard();
        }
       
    }
    useEffect(() => {
        setSelectedVal(Object.keys(selectedValues).map((element) => selectedValues[element].value));
    }, [selectedValues])

    const ChartCards = () => {
        return (
            <>
                <div className="card">
                    <div className="card-header border-bottom">
                        <h3 className="card-title">Energie/1j (Kwh)</h3>
                    </div>
                    <Chart
                        options={
                            {
                                chart: {
                                    id: 'chartEnergyPerDay'
                                },
                                xaxis: {
                                    type: 'datetime',
                                    categories: chartTimes
                                },
                                tooltip: {
                                    theme: 'dark',
                                    shared: false,
                                    x: {
                                        format: "dd/MM/yyyy"
                                    },

                                }
                            }
                        }
                        series={[{
                            name: 'Energie Consommée en Kwh',
                            data: chartDataPerDay
                        }]}
                        type="bar"
                        width={"100%"}
                        height={320}
                    />
                </div>
                <div className="card">
                    <div className="card-header border-bottom">
                        <h3 className="card-title">Energie/30j (Kwh)</h3>
                    </div>
                    <Chart
                        options={
                            {
                                chart: {
                                    id: 'chartEnergyPerMonth'
                                },
                                xaxis: {
                                    type: 'category'
                                },
                                tooltip: {
                                    theme: 'dark',
                                    shared: false,
                                    x: {
                                        format: "dd/MM/yyyy"
                                    },
                                }
                            }
                        }
                        series={
                            [{
                                name: 'Energie Consommée en Kwh',
                                data: [
                                    {
                                        x: ['Du', dateStart, 'A', dateEnd],
                                        y: chartDataPerMonth
                                    }
                                ]
                            }]}
                        type="bar"
                        width={"100%"}
                        height={320}
                    />
                </div>
            </>
        )
    }
    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="bilanEnergy" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">

                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Bilan d'énergie</h1>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">Sélectionnez une ou plusieurs machines </h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <label className="form-label col-md-2 col-lg-2 col-xl-1">Machines</label>
                                            <div className="form-group col-md-8 col-lg-8 col-xl-9 ">
                                                <SelectBox
                                                    options={availableOptions}
                                                    value={selectedValues}
                                                    defaultValue={selectedValues}
                                                    onChange={handleChange}
                                                    placeholder={"Selectionnez machine(s)..."}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    autosize={true}
                                                />

                                            </div>
                                            <div className='col-md-2 col-lg-2 col-xl-2'>
                                                <button className="btn btn-outline-primary btn-block" type="submit" onClick={(e) => { handleErrors(); handleSubmit(e) }}>
                                                    <i className="fa fa-bar-chart me-2"></i>Valider
                                                </button>
                                                <ToastContainer
                                                    className="toast-container"
                                                    autoClose={4000}
                                                    closeButton={false}
                                                    theme={"colored"}
                                                    icon={true}
                                                />
                                            </div>
                                            <div>
                                                    {   isLoading ? ( 
                                                            <BouncingDotsLoader/>
                                                    ) : showCard ? <ChartCards /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    );
}

export default BilanEnergie
