import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NumericInput from 'react-numeric-input';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditCompresseur() {
  const params = useParams()
  const id = params.id
  const navigate = useNavigate();
  const [errore, setErrore] = useState([]);
  const [data, setData] = useState({
    name_machine: '',
    max_value: '',
    numero: '',
    id: id
  });

  const {
    name_machine,
    max_value,
    numero,
  } = data

  function formatPression(num) {
    return num + ' BAR';
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    if (errore.includes(name)) {
      setErrore(errore.filter((e) => e !== name))
    }
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })

  }

  const handleTelChange = (event) => {
    const { name, value } = event.target
    if (errore.includes(name)) {
      setErrore(errore.filter((e) => e !== name))
    }
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleEditSubmit = async () => {
    const errorList = [];
    if (numero) {
        let lengthTel = numero.toString().length;
        if (lengthTel !== 11 || 
            numero.toString().slice(0, 3) !== "216") {
          toast.error("Veuillez respecter le format de saisie de N° tel indiqué si dessous");
          errorList.push("numero");
          setErrore(errorList);
          return; 
        }
      } else {
        setErrore([]);
      }

    const payload = {
        name_machine,
        id_machine: data.id,
        max_value: max_value ? max_value : "",
        numero: numero ? numero : ""
    };

    try {
        const response = await axios.post(
            process.env.REACT_APP_BACKEND_IP + ':' + process.env.REACT_APP_BACKEND_PORT + '/pression/saveDataCompresseur/',
            payload
        );
        if (response.status === 200) {
            toast.success(response.data.status);
            setTimeout(() => {
                navigate("/GestionCompresseur");
            }, 3000);
        } else {
            toast.error(response.data.message || "An error occurred");
        }
    } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred");
    }
};

  useEffect(() => {
    async function fetchparams() {
      let paramsCompresseur;
      try {
        paramsCompresseur = await axios.post(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/pression/getDataCompresseurById/'
          + id
        )
        setData((prevState) => ({
          ...prevState,
          name_machine: paramsCompresseur.data.compresseur.name_machine,
          max_value: paramsCompresseur.data.compresseur.max_value,
          numero: paramsCompresseur.data.compresseur.numero
        }))


      } catch (error) {
        console.log(error)
      }
    }
    fetchparams();
  }, [])


  return (
    <>
    {}
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="GestionCompresseur" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier paramètres compresseur</h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">{"Compresseur : " + name_machine}</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                        <label className="form-label col-md-4 col-lg-4 col-xl-3">Nom Machine</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                          <input
                                type="text"
                                value={name_machine}
                                className="form-control"
                                onChange={(e) => handleChange(e)}
                                name="name_machine"
                                placeholder="Enter nom machine"
                            />
                          </div>
                          <label className="form-label col-md-4 col-lg-4 col-xl-3">Valeur Maximale</label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <NumericInput
                              className={errore.includes("max_value") ? "form-control input-error" : "form-control"}
                              value={max_value ? max_value : ""}
                              placeholder="Saisir valeur Max"
                              name="max_value"
                              min={0}
                              step={0.01}
                              maxLength={8}
                              format={formatPression}
                              onChange={max_value => handleChange({ target: { value: max_value, name: 'max_value' } })}
                              onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        
                          <label className="form-label col-md-4 col-lg-4 col-xl-3"> N° Télèphone </label>
                          <div className="form-group col-md-8 col-lg-8 col-xl-3">
                            <input
                              type="number"
                              className={errore.includes("numero") ? "form-control input-error" : "form-control"}
                              value={numero ? numero : ""}
                              onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                              placeholder="216 xx xxx xxx"
                              name="numero"
                              onChange={handleTelChange}
                            />
                            <span className="span-error"> Veuillez saisir votre N° sous cette format 216 xx xxx xxx</span>
                          </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                          <div className="'col-md-12 col-lg-12 col-xl-2">
                            <button className="btn btn-outline-warning btn-block" type="submit" onClick={handleEditSubmit}>
                              Modifier
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default EditCompresseur;
